import React, {useState} from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {CircularProgress} from "@mui/material";
import {resetPassword} from "../services/AuthService";
import Container from "@mui/material/Container";
import {useDispatch, useSelector} from "react-redux";
import {startLoading, stopLoading} from "../reducers/loadingSlice";

export default function ChangePassword() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('')
    const isLoading = useSelector((state) => state.loading.backend)
    const dispatch = useDispatch()
    const loginUser = useSelector((state) => state.user.loginUser)


    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(startLoading())
        resetPassword(password).then(response => {
            if (response.status === 200) {
                setMessage("Password reset successfully")
            } else {
                setMessage("Reset password failed, please retry")
            }
        }).finally(() => dispatch(stopLoading()))
        // Perform password reset action here
        setMessage('Your password has been reset.');
        setPassword('');
        setConfirmPassword('');
    };

    return (
        <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
            <form onSubmit={handleSubmit} style={{maxWidth: "500px", margin: "0 auto"}}>
                <Typography variant="h6" gutterBottom>
                    Reset Password
                </Typography>
                <TextField
                    id="username"
                    label="Username"
                    type="text"
                    defaultValue={loginUser.sub}
                    fullWidth
                    disabled={true}
                    margin="normal"
                />

                <TextField
                    id="password"
                    label="New Password"
                    type="password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    fullWidth
                    required
                    margin="normal"
                />
                <TextField
                    id="confirm-password"
                    label="Confirm Password"
                    type="password"
                    value={confirmPassword}
                    onChange={(event) => setConfirmPassword(event.target.value)}
                    fullWidth
                    required
                    margin="normal"
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!password || !confirmPassword || isLoading || password !== confirmPassword}
                >
                    {isLoading ? <CircularProgress size={24} sx={{color: '#ffffff'}}/> : 'Reset Password'}

                </Button>
                {
                    (password && confirmPassword && password !== confirmPassword) &&
                    <Typography variant="body1" color="error" sx={{mt: 2}}>
                        Passwords do not match.
                    </Typography>
                }
                {message && (
                    <Typography variant="body1" color="secondary" sx={{mt: 2}}>
                        {message}
                    </Typography>
                )}
            </form>
        </Container>
    );
}