import {createSlice} from '@reduxjs/toolkit'
import {getLoggedInUser, getUserFromLocalStorage} from "../services/AuthService";

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        selectedUser: getLoggedInUser(),
        loginUser: getUserFromLocalStorage(),
        allUsers: [getLoggedInUser()],
        expressMode: false
    },
    reducers: {
        loginUser: (state, action) => {
            state.loginUser = action.payload
            state.selectedUser = {
                userName: state.loginUser?.sub,
                name: state.loginUser?.name,
                role: state.loginUser?.roles[0],
                id: state.loginUser?.uid
            }
        },
        logoutUser: (state) => {
            state.loginUser = null
            state.allUsers = []
            state.selectedUser = null
        },
        selectUser: (state, action) => {
            state.selectedUser = action.payload
        },
        unselectUser: (state) => {
            state.selectedUser = null
        },
        setAllUsers: (state, action) => {
            state.allUsers = action.payload
        },
        toggleExpressMode: (state) => {
            state.expressMode = !state.expressMode
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    loginUser,
    logoutUser,
    selectUser, unselectUser,
    setAllUsers,
    toggleExpressMode
} = userSlice.actions

export default userSlice.reducer