import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import {v4 as uuidv4} from 'uuid';
const borderColor = '#ffffff'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottom: '1 solid #000000',
        backgroundColor: '#ffffff',
        alignItems: 'center',
        height: 24,
        textAlign: 'center',
        fontWeight: 600,
        flexGrow: 1,
    },
    description: {
        fontFamily: 'Noto Sans SC',
        width: '60%',

        borderRightColor: borderColor,
        fontWeight: 600,
        borderRightWidth: 1,
        textAlign: 'left',
    },
    qty: {
        fontFamily: 'Noto Sans SC',
        width: '10%',
        borderRightColor: borderColor,
        fontWeight: 600,
        borderRightWidth: 1,
        textAlign: 'right',
    },
    rate: {
        fontFamily: 'Noto Sans SC',
        width: '15%',
        borderRightColor: borderColor,
        fontWeight: 600,
        borderRightWidth: 1,
        textAlign: 'right',
    },
    amount: {
        fontFamily: 'Noto Sans SC',
        fontWeight: 600,
        width: '15%',
        textAlign: 'right',
    },
});

const InvoiceTableHeader = () => (
    <View key={uuidv4()} style={styles.container}>
        <Text style={styles.description}>Item Description</Text>
        <Text style={styles.qty}>Quantity</Text>
        <Text style={styles.rate}>Unit Price</Text>
        <Text style={styles.rate}>GST</Text>
        <Text style={styles.amount}>Amount</Text>
    </View>
);

export default InvoiceTableHeader