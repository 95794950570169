import {getheaders} from "./AuthService";


export function uploadImage(url, file) {
    let headers = {
        'Content-Type': 'application/octet-stream',
    }

    let requestOptions = {
        method: 'PUT', headers: headers, redirect: 'follow', body: file
    };
    return fetch(url, requestOptions)
}

export function prepareUploadImage(title, imageType, agentId, quoteId) {
    let reqBody = {
        title: title,
        imageType: imageType,
        agentId: agentId,
        quoteId: quoteId
    }
    let requestOptions = {
        method: 'POST', headers: getheaders(), redirect: 'follow', body: JSON.stringify(reqBody)
    };
    return fetch(process.env.REACT_APP_BACKEND_URL + "/api/image/upload", requestOptions)
}

export function populateImageUrl(imageIds) {
    let requestOptions = {
        method: 'POST', headers: getheaders(), redirect: 'follow', body: JSON.stringify(imageIds)
    };
    return fetch(process.env.REACT_APP_BACKEND_URL + "/api/image/fetch", requestOptions)
}

