import {Button, Input, InputAdornment} from "@mui/material";
import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import {startLoading, stopLoading} from "../reducers/loadingSlice";
import {createAdhocNode} from "../services/NodeService";
import {addToCart} from "../reducers/cartSlice";


export default function AdhocPrice() {
    const loginUser = useSelector((state) => state.user.loginUser)
    const selectedUser = useSelector((state) => state.user.selectedUser)
    const [adhocItemName, setAdhocItemName] = React.useState('');
    const [adhocItemPrice, setAdhocItemPrice] = React.useState(0);
    const isLoading = useSelector((state) => state.loading.backend)

    const dispatch = useDispatch()

    function handleClickAddAdhocItem() {
        dispatch(startLoading())
        createAdhocNode(adhocItemName, selectedUser.id, adhocItemPrice).then(async (response) => {
            if (response.status === 200) {
                let data = await response.json()
                dispatch(addToCart(
                    {
                        agentId: selectedUser.id,
                        node: data
                    }))
                setAdhocItemName('')
                setAdhocItemPrice(0)
            } else {
                console.error(response)
            }
        }).finally(() => {
            dispatch(stopLoading())
        })

    }


    return (
        <Container component="main" maxWidth="100%" sx={{px: 0}}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={8}>
                    <Input id="customitem" placeholder={'Other Item'}
                           value={adhocItemName} onChange={(e) => setAdhocItemName(e.target.value)}
                           label="Standard" variant="standard" fullWidth/>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Input id="outlined-basic"
                           startAdornment={<InputAdornment position="start">$</InputAdornment>}
                           value={adhocItemPrice} onChange={(e) => setAdhocItemPrice(e.target.value)}
                           variant="standard"
                           fullWidth={true}
                           autoComplete={'off'}
                           type={'tel'}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <Button variant="outlined"
                            disabled={!adhocItemName || adhocItemPrice <= 0 || isLoading}
                            onClick={handleClickAddAdhocItem}
                            size="small">Add</Button>
                </Grid>
            </Grid>


        </Container>
    )
}