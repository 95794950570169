import {createSlice} from '@reduxjs/toolkit'

export const loadingSlice = createSlice({
    name: 'loading',
    initialState: {
        backend: false,
    },
    reducers: {
        flip: (state) => {
            state.backend = !state.backend
        },
        startLoading: (state) => {
            state.backend = true
        },
        stopLoading: (state) => {
            state.backend = false
        },
        incrementByAmount: (state, action) => {
            state.value += action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    flip, startLoading, stopLoading
} = loadingSlice.actions

export default loadingSlice.reducer