import {configureStore} from '@reduxjs/toolkit'
import loadingReducer from './reducers/loadingSlice'
import userReducer from './reducers/userSlice'
import cartReducer from './reducers/cartSlice'
import nodeReducer from './reducers/nodeSlice'
import {load, save} from 'redux-localstorage-simple';


export default function configureAppStore(openReplayMiddleware) {
    return configureStore({
        reducer: {
            loading: loadingReducer,
            user: userReducer,
            cart: cartReducer,
            node: nodeReducer
        },
        middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), save(), openReplayMiddleware],
        preloadedState: load(), // Optionally load the initial state from localStorage
    });
}