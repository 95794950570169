import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import * as React from "react";
import {useSelector} from "react-redux";

export default function PriceCard(props) {
    const isLoading = useSelector((state) => state.loading.backend)
    const selectedUser = useSelector((state) => state.user.selectedUser)
    const cartItems = useSelector((state) => state.cart[selectedUser.id]?.nodes) || []

    function inCart() {
        return cartItems && cartItems.filter(item => item.id === props.pricecard.id).length > 0;
    }

    return (

        <Card style={{minHeight: "350px"}}>
            <CardHeader style={{minHeight: "100px"}}
                        title={props.pricecard.name}
                        subheader={props.pricecard.subheader}
                        titleTypographyProps={{align: 'center'}}
                // action={props.pricecard.title === 'Pro' ? <StarIcon /> : null}
                        subheaderTypographyProps={{
                            align: 'center',
                        }}
                        sx={{
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'light'
                                    ? theme.palette.grey[200]
                                    : theme.palette.grey[700],
                            border: '1px solid',
                            borderRadius: '5px',
                            borderColor: (theme) => theme.palette.primary.main
                        }}
                        action={
                            <IconButton
                                disabled={isLoading}
                                aria-label="settings" onClick={() => props.handleEditClick(props.pricecard)}>
                                <EditIcon/>
                            </IconButton>
                        }
            />
            <CardContent style={{minHeight: "200px"}}>
                {props.pricecard.leaf && <Box
                    sx={{
                        display: props.pricecard.price == 0 ? 'none' : 'flex',
                        justifyContent: 'center',
                        alignItems: 'baseline',
                        flexWrap: 'wrap',
                        mb: 2,
                    }}
                >
                    <Typography component="h2" variant="h3" color="text.primary">
                        ${props.pricecard.basePrice}
                    </Typography>
                    {props.pricecard.upperPrice && props.pricecard.upperPrice > 0 &&
                        <Typography component="h2" variant="h3" color="text.primary">
                            -${props.pricecard.upperPrice}
                        </Typography>}
                    <Typography variant="h8" color="text.secondary">
                        AUD
                    </Typography>
                    <Typography variant="h8" color="text.secondary" sx={{alignSelf: 'flex-start', width: '100%'}}>
                        {props.pricecard.bundlePrice && props.pricecard.bundlePrice > 0 ? '$' + props.pricecard.bundlePrice + ' if bundled' : ''}
                    </Typography>
                    <Typography variant="h8" color="text.secondary" sx={{alignSelf: 'flex-start', width: '100%'}}>
                        {props.pricecard.extraUnitPrice > 0 &&
                            `plus $${props.pricecard.extraUnitPrice} per ${props.pricecard.extraUnitName ?? ''}`}
                    </Typography>
                </Box>}
                <Typography
                    variant="subtitle1"
                    align="center"
                >
                    {props.pricecard.description}
                </Typography>

            </CardContent>
            <CardActions>
                <Button fullWidth
                        onClick={() => props.handleCardAction(props.pricecard)}
                        disabled={isLoading || inCart()}
                        variant={props.pricecard.leaf ? 'contained' : 'outlined'}>
                    {props.pricecard.leaf ?
                        inCart() ? 'Added' : 'Add to cart' :
                        'Find out more'}
                </Button>
            </CardActions>
        </Card>

    )
}
      