import React, {useEffect, useState} from "react";
import {deleteNode, refreshNodes, ROOT_NODE_ID, updateNode, updatePrice} from "../services/NodeService";
import {Autocomplete, Button, CircularProgress, Grid, InputAdornment, TextField, Typography} from '@mui/material';
import Container from "@mui/material/Container";
import PriceCard from "./PriceCard";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import ListItemText from "@mui/material/ListItemText";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from '@mui/icons-material/Remove';
import AddNode from "./AddNode";
import {useNavigate} from "react-router-dom";

import {useDispatch, useSelector} from "react-redux";
import {startLoading, stopLoading} from "../reducers/loadingSlice";
import {addToCart} from "../reducers/cartSlice";
import BreadCrumb from "./Breadcrumb";
import SearchIcon from "@mui/icons-material/Search";
import Highlighter from "react-highlight-words";


export default function EditNode(props) {
    const {treeEditMode} = props;

    const [addChildFlag, setAddChildFlag] = useState(false);
    const isLoading = useSelector((state) => state.loading.backend)
    const selectedUser = useSelector((state) => state.user.selectedUser)
    const cartItems = useSelector((state) => state.cart[selectedUser.id]?.nodes) || []

    const allNodes = useSelector((state) => state.node.allNodes)
    const allNodesMap = useSelector((state) => state.node.allNodesMap)


    const dispatch = useDispatch()
    const [message, setMessage] = useState()
    let navigate = useNavigate();

    const [nodeData, setNodeData] = useState({})
    const [searchText, setSearchText] = useState('')

    function resetNodeData() {
        setNodeData({
            id: '',
            name: '',
            description: '',
            basePrice: 0,
            upperPrice: 0,
            bundlePrice: 0,
            discountPrice: 0,
            extraUnitName: '',
            extraUnitPrice: 0,
            "children": []
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(startLoading())

        updateNode(nodeData).then((r) => {
            if (r.status === 200) {
                updatePrice(selectedUser.id, nodeData).then((r) => {
                    if (r.status === 200) {
                        refreshNodes(nodeData.id, selectedUser.id, setNodeData, false)
                    }
                })
            }
        }).finally(() => dispatch(stopLoading()));
    };

    useEffect(() => {
        dispatch(startLoading())
        const searchParams = new URLSearchParams(window.location.search);
        let id = searchParams.get('id');
        if (id === null) {
            id = ROOT_NODE_ID
        }
        refreshNodes(id, selectedUser.id, setNodeData, false).finally(() => dispatch(stopLoading()));
    }, [window.location.search, selectedUser]);

    useEffect(() => {
        if (treeEditMode) {
            setSearchText(allNodesMap[nodeData.parentId]?.name ?? '')
        }

    }, [allNodesMap, nodeData]);

    function handleUpdateData(event, field) {
        const value = event.target.value
        setNodeData(prevState => {
            return {
                ...prevState,
                [field]: value
            }
        })
    }

    function handleDeleteNode(nodeId) {
        dispatch(startLoading())
        deleteNode(nodeId).then((r) => {
            if (r.status === 200) {
                return refreshNodes(nodeData.id, selectedUser.id, setNodeData, false)
            }

        }).finally(() => dispatch(stopLoading()));
    }

    const handleCardAction = (tier) => {
        if (tier.leaf) {
            dispatch(addToCart(
                {
                    agentId: selectedUser.id,
                    node: tier
                }))
        } else {
            dispatch(startLoading())
            //Drill down
            navigate(`?id=${tier.id}`);
        }
    };

    function changeParent(value) {
        console.log(value)
        setNodeData({
            ...nodeData,
            parentId: value.id
        })

        return undefined;
    }

    return (
        <Container maxWidth="xl" sx={{mt: 4, mb: 4}}>


            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <BreadCrumb nodeData={nodeData}></BreadCrumb>
                </Grid>
                <Grid item xs={9}>
                    <Box
                        sx={{
                            marginTop: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',

                        }}
                    >
                        <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                            <LockOutlinedIcon/>
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            {isLoading ? (<CircularProgress size={24}/>) : 'Item Details'}

                        </Typography>

                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 3}}>
                            <Grid container spacing={2}>
                                {treeEditMode && <Grid item xs={12} sm={12}>
                                    <Autocomplete
                                        disablePortal
                                        freeSolo
                                        disableClearable
                                        autoHighlight
                                        id="combo-box-demo"
                                        options={allNodes}
                                        value={{name: searchText}}
                                        disabled={isLoading}
                                        onChange={(event, value) => changeParent(value)}
                                        onInputChange={(event, value) => {
                                            setSearchText(value ?? '')
                                        }}
                                        getOptionLabel={(node) => node.name ? node.name : ''} // Specify the property to display in the autocomplete
                                        isOptionEqualToValue={(option, value) => option && option.id === value.id}
                                        fullWidth
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                       InputProps={{
                                                           ...params.InputProps,
                                                           startAdornment: (
                                                               <InputAdornment position="start">
                                                                   <SearchIcon/>
                                                               </InputAdornment>
                                                           ),
                                                       }}
                                                       variant="outlined"
                                                       type={"text"}
                                                // value={searchText}
                                                       label="Parent Node"/>}
                                        renderOption={(props, node) => {
                                            return (
                                                <li {...props}>
                                                    <Highlighter
                                                        highlightStyle={{
                                                            fontWeight: "900",
                                                            backgroundColor: "transparent"
                                                        }}
                                                        searchWords={[searchText]}
                                                        autoEscape={true}
                                                        textToHighlight={node.name ?? ""}
                                                    />
                                                </li>
                                            );
                                        }}
                                    />
                                </Grid>}
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        label="Name"
                                        disabled={isLoading}
                                        value={nodeData.name}
                                        onChange={(event) => handleUpdateData(event, 'name')}
                                        required
                                        autoComplete={"off"}
                                        fullWidth
                                        InputLabelProps={{shrink: true}}
                                        id="Name"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        label="Description"
                                        disabled={isLoading}
                                        value={nodeData.description}
                                        onChange={(event) => handleUpdateData(event, 'description')}
                                        fullWidth
                                        multiline
                                        autoComplete={"off"}
                                        rows={5}
                                        id="Description"
                                        name="Description"
                                        InputLabelProps={{shrink: true}}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        id={"basePrice"}
                                        disabled={isLoading}
                                        fullWidth
                                        label="Base Price"
                                        type="number"
                                        autoComplete={"off"}
                                        value={nodeData.basePrice || ''}
                                        onChange={(event) => handleUpdateData(event, 'basePrice')}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        id={"upperPrice"}
                                        disabled={isLoading}
                                        fullWidth
                                        label="Uppper Price"
                                        type="number"
                                        autoComplete={"off"}
                                        value={nodeData.upperPrice || ''}
                                        onChange={(event) => handleUpdateData(event, 'upperPrice')}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        fullWidth
                                        disabled={isLoading}
                                        label="Bundle Price"
                                        autoComplete={"off"}
                                        type="number"
                                        value={nodeData.bundlePrice || ''}
                                        onChange={(event) => handleUpdateData(event, 'bundlePrice')}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        fullWidth
                                        disabled={isLoading}
                                        label="Discount"
                                        type="number"
                                        autoComplete={"off"}
                                        value={nodeData.discountPrice || ''}
                                        onChange={(event) => handleUpdateData(event, 'discountPrice')}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        fullWidth
                                        disabled={isLoading}
                                        label="Extra Unit Price"
                                        type="number"
                                        autoComplete={"off"}
                                        value={nodeData.extraUnitPrice || ''}
                                        onChange={(event) => handleUpdateData(event, 'extraUnitPrice')}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    per
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        fullWidth
                                        disabled={isLoading}
                                        label="Extra Unit Name"
                                        type="text"
                                        autoComplete={"off"}
                                        value={nodeData.extraUnitName || ''}
                                        onChange={(event) => handleUpdateData(event, 'extraUnitName')}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>

                            </Grid>
                            <Button
                                type="submit"

                                variant="contained"
                                sx={{mt: 3, mb: 2}}
                                disabled={isLoading || !nodeData.name}
                            >
                                {isLoading ? (<CircularProgress size={24}/>) : 'Save'}
                            </Button>
                            {nodeData && nodeData.parentId &&
                                <Button
                                    variant="outlined"
                                    sx={{mt: 3, mb: 2, ml: 3}}
                                    disabled={isLoading}
                                    onClick={() => {
                                        dispatch(startLoading())
                                        navigate(`?id=${nodeData.parentId}`)
                                    }}
                                >
                                    Back to Parent
                                </Button>}
                            <Typography component="h1" variant="h5" align="left">
                                Sub categories
                                <IconButton
                                    disabled={isLoading}
                                    onClick={() => {
                                        setAddChildFlag(!addChildFlag)
                                    }}>
                                    {addChildFlag ? <RemoveIcon/> : <AddIcon/>}
                                </IconButton>
                            </Typography>

                            <List>
                                {addChildFlag && <AddNode

                                    nodeData={nodeData}
                                    setNodeData={setNodeData}/>}


                                {nodeData.children && nodeData.children
                                    .sort((a, b) => b.modifiedDate - a.modifiedDate)
                                    .map((child) => (
                                        <ListItem
                                            key={child.id}
                                            secondaryAction={
                                                <>
                                                    <IconButton edge="end"
                                                                onClick={() => {
                                                                    // resetNodeData();
                                                                    navigate(`?id=${child.id}`);
                                                                }
                                                                }
                                                                disabled={isLoading}
                                                                aria-label="edit">
                                                        <EditIcon/>
                                                    </IconButton>
                                                    {child.leaf &&
                                                        <IconButton edge="end"
                                                                    onClick={() => {
                                                                        handleDeleteNode(child.id, setNodeData);
                                                                    }}
                                                                    aria-label="delete"
                                                                    disabled={isLoading}

                                                        >
                                                            <DeleteIcon/>
                                                        </IconButton>
                                                    }

                                                </>
                                            }
                                        >
                                            <ListItemText
                                                primary={child.name}

                                                secondary={
                                                    <>
                                                        {child.description || 'No description'}
                                                        <br/>
                                                        {child.leaf && (
                                                            <>
                                                                Base: {child.basePrice}. Bundled: {child.bundlePrice}.
                                                                Discount: {child.discountPrice}.
                                                            </>)}
                                                    </>
                                                }
                                            />

                                        </ListItem>
                                    ))}
                            </List>
                        </Box>
                    </Box>

                </Grid>
                <Grid
                    item
                    xs={3}
                    md={3}
                    sx={{mt: 15}}
                >

                    <PriceCard pricecard={nodeData}
                               cartItems={[]}
                               handleCardAction={handleCardAction}
                               handleEditClick={() => {
                               }}
                    />
                </Grid>

            </Grid>
        </Container>
    )
}