import * as React from "react";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {startLoading, stopLoading} from "../reducers/loadingSlice";
import {attachImageToQuote} from "../reducers/cartSlice";
import {MuiFileInput} from "mui-file-input";
import {prepareUploadImage, uploadImage} from "../services/ImageService";
import {CircularProgress} from "@mui/material";


export default function ImageUpload() {
    const selectedUser = useSelector((state) => state.user.selectedUser)
    const activeQuote = useSelector((state) => state.cart[selectedUser.id]?.activeQuote)
    const [isUploading, setIsUploading] = useState(false)

    const dispatch = useDispatch()

    const [files, setFiles] = useState(null);

    function delayOneSecond() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, 1000); // 1000 milliseconds = 1 second
        });
    }

    const handleUpload = async (newValue) => {
        dispatch(startLoading())
        setIsUploading(true)
        setFiles(newValue);
        for (const file of newValue) {
            const fileType = file?.type?.split("/")[1]?.toLowerCase() || "unknown";
            const fileName = file?.name || "unknown";
            await prepareUploadImage(fileName, fileType, selectedUser.id, activeQuote).then(async (response) => {
                if (response.status === 200) {
                    let data = await response.json()
                    let signedUrl = data.uploadUrl
                    await uploadImage(signedUrl, file);
                    delayOneSecond().then(() => {
                        dispatch(attachImageToQuote({
                            agentId: selectedUser.id,
                            image: data
                        }))
                    });


                } else {
                    console.error(response)
                }
            })
        }
        setFiles(null)
        dispatch(stopLoading())
        setIsUploading(false)

    }


    return (

        <>
            <MuiFileInput
                sx={{mt: 1}}
                type="file"
                inputProps={{accept: "image/*, video/*, application/pdf"}}
                placeholder="Click to Insert your images"
                multiple
                disabled={isUploading}
                value={files}
                onChange={handleUpload}
                fullWidth={true}
                size={'small'}
            />
            {isUploading && <div><CircularProgress size={12}sx={{mr: 1}}/>Uploading...Please wait </div>

            }
        </>

    )
}