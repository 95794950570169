import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux'

import Tracker from '@openreplay/tracker';
import trackerAssist from '@openreplay/tracker-assist';
import trackerRedux from '@openreplay/tracker-redux/cjs';
import configureAppStore from "./store";


const root = ReactDOM.createRoot(document.getElementById('root'));

const tracker = new Tracker({
    projectKey: "JybdERduwtMc8bd2tHVg",
    // ingestPoint: "https://openreplay.newbodaservices.com.au/ingest",
    __DISABLE_SECURE_MODE: true,
    defaultInputMode: '0',
    network: {
        capturePayload: true, // Capture HTTP payload
        failuresOnly: true // Capture only failed requests
    },

});
tracker.use(trackerAssist())
const openReplayMiddleware = tracker.use(trackerRedux())
const store = configureAppStore(openReplayMiddleware)

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App tracker={tracker}/>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
