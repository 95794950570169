import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {startLoading} from "../reducers/loadingSlice";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

export default function BreadCrumb(props) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const breadcrumbs = props.nodeData.parentPathInfo && props.nodeData.parentPathInfo.slice().reverse().map((item) => {
        return (
            <Link underline="hover"
                  sx={{cursor: 'pointer'}}
                  key={item.id}
                  color="inherit"
                  onClick={() => {
                      const searchParams = new URLSearchParams(window.location.search);
                      let id = searchParams.get('id');
                      if (id === item.id) {
                          return
                      }
                      dispatch(startLoading())
                      navigate(`?id=${item.id}`)
                  }}
            >
                {item.name}
            </Link>
        )
    })

    return (

        <Breadcrumbs
            sx={{ml: 1}}
            separator={<NavigateNextIcon fontSize="small"/>}
            aria-label="breadcrumb"
        >
            {breadcrumbs}
        </Breadcrumbs>

    );

}