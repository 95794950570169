import Tree from "react-d3-tree";
import * as React from "react";
import {useEffect, useState} from "react";
import Container from "@mui/material/Container";
import {getNode, refreshPrice, ROOT_NODE_ID} from "../services/NodeService";
import './TreeView.css';
import {useNavigate} from "react-router-dom";
import {Autocomplete, CircularProgress, Dialog, InputAdornment, Slider} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import EditNode from "./EditNode";
import {useDispatch, useSelector} from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {startLoading, stopLoading} from "../reducers/loadingSlice";
import TextField from "@mui/material/TextField";
import Highlighter from "react-highlight-words";
import SearchIcon from "@mui/icons-material/Search";
import {UserSelector} from "./UserSelector";
import {refreshRoot} from "../reducers/nodeSlice";


export default function TreeView() {

    const [nodeData, setNodeData] = useState({"children": []});
    const navigate = useNavigate();
    const selectedUser = useSelector((state) => state.user.selectedUser)
    const [showDialog, setShowDialog] = useState(false);
    const [rootNode, setRootNode] = useState({
        id: ROOT_NODE_ID
    });
    const isLoading = useSelector((state) => state.loading.backend)
    const [firstLayerNodes, setFirstLayerNodes] = useState([]);
    const [treeSize, setTreeSize] = useState(300);
    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState('')


    useEffect(() => {
        dispatch(startLoading())
        refreshNode();
    }, [rootNode, selectedUser]);

    async function setAttributes(el) {
        let attributes = el.attributes ?? {};
        if (el.basePrice) {
            attributes.Price = el.basePrice;
        }
        if (el.upperPrice) {
            attributes.Price = attributes.Price + '-' + el.upperPrice;
        }
        if (el.bundlePrice) {
            attributes.Bundle = el.bundlePrice;
        }
        if (el.discountPrice) {
            attributes.discountPrice = el.discountPrice;
        }
        if (el.extraUnitPrice) {
            attributes.ExtraUnit = el.extraUnitPrice;
        }
        if (el.extraUnitName) {
            attributes.ExtraUnit = attributes.ExtraUnit + ' per ' + el.extraUnitName;
        }

        el.attributes = {...attributes};
        if (rootNode.id !== ROOT_NODE_ID && el.children !== undefined) {
            await refreshPrice(el, selectedUser.id)
        }

        if (el.children) {

            for (const child of el.children) {
                await setAttributes(child);
            }
        }
    }

    function refreshNode() {
        getNode(rootNode.id, 'tree').then(async (response) => {
            if (response.status === 200) {
                let data = await response.json()
                await setAttributes(data)

                setNodeData(data)
                if (rootNode.id === ROOT_NODE_ID) {
                    dispatch(refreshRoot(data))
                    const nameMap = new Map();
                    const nameAppended = data.children.map((child) => {
                        let name = child.name;
                        if (nameMap.has(name)) {
                            const randomSuffix = Math.floor(Math.random() * 100);
                            name = `${name} (${randomSuffix})`;
                        }
                        nameMap.set(name, true);
                        return {
                            ...child,
                            name,
                        };
                    });

                    setFirstLayerNodes(nameAppended)
                }
            } else {
                setNodeData({"children": []})
            }
        }).finally(() => {dispatch(stopLoading())});
    }

    const toggleDialog = () => {
        setShowDialog(!showDialog);
    }

    function handleClose() {
        setShowDialog(false);
        dispatch(startLoading())
        refreshNode()

    }

    const separation = {siblings: 0.5, nonSiblings: 0.5};


    return (
        <Container disableGutters maxWidth="100&" component="tree" sx={{pt: 4, pb: 6, height: "2000px", position: "relative"}}>
            {isLoading && <CircularProgress
                sx={{width: 20, position: "absolute", top: 10, left: "auto", zIndex: 0}}
                size={24} />}
            <Container>
                <Box sx={{ width: 250 ,position: "absolute", top: 0, right: 0, zIndex: 1,}}>
                    <UserSelector/>
                </Box>

            <Autocomplete
                sx={{width: 400, position: "absolute", top: 20, left: 20, zIndex: 0, maxHeight: '80vh'}}
                disablePortal
                freeSolo
                disableClearable
                autoHighlight
                id="combo-box-demo"
                options={firstLayerNodes}
                disabled={isLoading}
                onChange={(event, value) => setRootNode(value)}
                onInputChange={(event, value) => {
                    setSearchText(value)
                }}
                getOptionLabel={(node) => node.name} // Specify the property to display in the autocomplete
                isOptionEqualToValue={(option, value) => option && option.id === value.id}
                fullWidth
                renderInput={(params) =>
                    <TextField {...params}
                               autoFocus
                               InputProps={{
                                   ...params.InputProps,
                                   startAdornment: (
                                       <InputAdornment position="start">
                                           <SearchIcon />
                                       </InputAdornment>
                                   ),
                               }}
                               variant="standard"
                               type={"search"}
                               value={searchText}
                                label="Search"/>}
                renderOption={(props, node) => {
                    return (
                        <li {...props}>
                            <Highlighter
                                highlightStyle={{fontWeight: "900", backgroundColor: "transparent"}}
                                searchWords={[searchText]}
                                autoEscape={true}
                                textToHighlight={node.name ?? ""}
                            />
                        </li>
                    );
                }}
            />

            <Box sx={{ width: 250 ,position: "absolute", top: 80, left: 20, zIndex: 0,}}>

                <Typography id="non-linear-slider" gutterBottom>
                    Tree size
                </Typography>

            <Slider
                min={100}
                step={30}
                max={600}

                value={treeSize}
                onChange={(event, newValue) => {
                    setTreeSize(newValue)
                }}
                aria-label="Default"
                valueLabelDisplay="auto" />
            </Box>
            </Container>

            <Tree data={nodeData}
                  draggable={true}
                // renderCustomNodeElement={renderCustomNodeElement}
                  translate={{x: 50, y: 50}}
                  rootNodeClassName="node"
                  branchNodeClassName="node"
                  leafNodeClassName="node"
                  separation={separation}
                  transitionDuration={2000}
                  collapsible={false}
                  nodeSize={{x: treeSize, y: 200}}
                  onNodeClick={(event, data) => {
                      navigate(`?id=${event.data.id}`);
                        toggleDialog()
                  }}


                  />

            <Dialog open={showDialog} onClose={handleClose} maxWidth="lg" fullWidth>
                <EditNode treeEditMode={true}/>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    style={{position: 'absolute', top: '10px', right: '10px', color: '#000'}}
                >
                    <CloseIcon/>
                </IconButton>
            </Dialog>
        </Container>
    )
}