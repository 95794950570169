import React, {Suspense, useState} from 'react'
import {useImage} from 'react-image'
import IconButton from "@mui/material/IconButton";
import {CircularProgress, Dialog} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {useDispatch, useSelector} from "react-redux";
import {removeImageFromQuote} from "../reducers/cartSlice";
import fileDownload from "js-file-download";
import {startLoading, stopLoading} from "../reducers/loadingSlice";

function isImageFile(imageType) {
    // List of image extensions (add more if needed)
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "tiff", "webp", "svg"];
    return imageExtensions.includes(imageType);
}

function isVideoFile(imageType) {
    // List of image extensions (add more if needed)
    const videoFileExtensions = [
        "mp4",
        "avi",
        "x-msvideo",
        "x-matroska",
        "quicktime",
        "x-ms-wmv",
        "x-flv",
        "webm",
        "mpeg",
        "3gpp",
        "x-m4v",
        "vnd.rn-realmedia",
        "x-matroska",
        "vnd.dlna.mpeg-tts",
        "ogg",
    ];
    return videoFileExtensions.includes(imageType);
}

function MyImageComponent(props) {

    const {item, handleImageClick, isDownloading, readOnly} = props

    const dispatch = useDispatch()
    const isLoading = useSelector((state) => state.loading.backend)

    let srcList =  [item.thumbnailUrl, item.downloadUrl, '/loading.gif']
    if (!isImageFile(item.imageType)) {
        if (isDownloading) {
            srcList = ['/loading.gif']
        } else {
            if (isVideoFile(item.imageType)) {
                srcList = ['/video.png']
            } else {
                srcList = ['/attachment.png']
            }
        }
    }

    const {src, isLoadingImage} = useImage({
        srcList: srcList
    })

    function handleOnDelete(item) {
        dispatch(removeImageFromQuote({
            agentId: props.selectedUser.id,
            image: item
        }))

    }

    return (

        <div style={{position: 'relative'}}>
            {isLoadingImage ? <CircularProgress size={24} sx={{color: '#000000'}}/> :
                <div style={{ textAlign: 'center' }}>
                    <img
                        src={src}
                        alt="Image"
                        width={100}
                        height={100}
                        onClick={() => {
                            handleImageClick();
                        }}
                        style={{ cursor: isLoading? 'disabled': 'pointer' }}
                    />
                    <div style={{ marginTop: 5, width: 120,  }}> {/* Adjust marginTop as needed */}
                        {item.title}
                    </div>
                </div>}
            {!readOnly &&
                <span
                    style={{
                        position: 'absolute',
                        top: '0px',
                        right: '5px',
                        cursor: 'pointer',
                        color: 'red',
                        fontSize: '20px',
                        zIndex: 1,
                    }}
                    onClick={() => handleOnDelete(item)} // Assuming the item has an ID property
                >
        &#10007;
      </span>}
        </div>
    )
}

export default function ImageComponent(props) {
    const {item} = props;
    const [showDialog, setShowDialog] = useState(false);
    const isLoading = useSelector((state) => state.loading.backend)
    const [isDownloading, setIsDownloading] = useState(false)
    const dispatch = useDispatch()

    async function handleImageClick() {
        if (isLoading) return
        dispatch(startLoading())

        if (isImageFile(item.imageType)) {
            toggleDialog()
        } else {
            setIsDownloading(true)
            const title = item.title || 'untitled';
            const response = await fetch(item.downloadUrl);
            const blob = await response.blob();
            fileDownload(blob, title);
            setIsDownloading(false)
        }
        dispatch(stopLoading())

    }
    const toggleDialog = () => {
        setShowDialog((prevState) => !prevState);
    };

    return (
        <>
            <Suspense>
                <MyImageComponent handleImageClick={handleImageClick} item={item} isDownloading={isDownloading}{...props}/>
            </Suspense>
            <Dialog open={showDialog} onClose={toggleDialog} maxWidth="md" fullWidth>
                <img src={item.downloadUrl} alt="Enlarged Image" style={{width: '100%'}}/>
                <IconButton
                    aria-label="close"
                    onClick={toggleDialog}
                    style={{position: 'absolute', top: '10px', right: '10px', color: '#fff'}}
                >
                    <CloseIcon/>
                </IconButton>
            </Dialog>
        </>

    )
}