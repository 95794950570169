import {createSlice} from '@reduxjs/toolkit'

export const cartSlice = createSlice({
    name: 'cart',
    initialState: {},
    reducers: {
        showDialog: (state, action) => {
            let cart = state[action.payload.agentId] ?? {};
            cart.activeQuote = action.payload.activeQuote;
            cart.confirDialogOpen = true;
            state[action.payload.agentId] = cart;
        },
        hideDialog: (state, action) => {
            let cart = state[action.payload.agentId] ?? {};
            cart.confirDialogOpen = false;
            state[action.payload.agentId] = cart;
        },
        setActiveQuote: (state, action) => {
            let cart = state[action.payload.agentId] ?? {};
            cart.activeQuote = action.payload.activeQuote;
            state[action.payload.agentId] = cart;
        },
        addToCart: (state, action) => {
            let cart = state[action.payload.agentId]
            let node = action.payload.node
            if (!cart) {
                cart = {};
            }
            if (!cart.nodes) {
                cart.nodes = [];
            }
            let nodes = cart.nodes;
            //If the item already exists, just replace
            let index = nodes.findIndex((item) => item.id === node.id);
            if (index !== -1) {
                nodes[index] = node;
            } else {
                nodes.push(node);
            }
            state[action.payload.agentId] = cart;
        },
        refreshCart: (state, action) => {
            let cart = state[action.payload.agentId] ?? {};
            let nodes = action.payload.nodes
            cart.nodes = nodes;
            state[action.payload.agentId] = cart;
        },
        updateQuote: (state, action) => {
            let cart = state[action.payload.agentId] ?? {};
            cart.nodes = action.payload.nodes;
            cart.priceOverride = action.payload.priceOverride;
            cart.images = action.payload.images;
            cart.extraUnit = action.payload.extraUnit;
            cart.address = action.payload.address;
            cart.description = action.payload.description;
            cart.reference = action.payload.reference;
            state[action.payload.agentId] = cart;
        },
        updatePriceOverride: (state, action) => {
            let cart = state[action.payload.agentId]
            cart.priceOverride = {...action.payload.priceOverride}
            state[action.payload.agentId] = cart;
        },
        updateExtraUnit: (state, action) => {
            let cart = state[action.payload.agentId]
            cart.extraUnit = {...action.payload.extraUnit}
            state[action.payload.agentId] = cart;
        },
        updateQuoteAddress: (state, action) => {
            let cart = state[action.payload.agentId]
            cart.address = action.payload.address
            state[action.payload.agentId] = cart;
        },
        attachImageToQuote: (state, action) => {
            let cart = state[action.payload.agentId]
            let images = cart.images ?? [];
            images.push(action.payload.image)
            cart.images = images;
            state[action.payload.agentId] = cart;
        },
        removeImageFromQuote: (state, action) => {
            let cart = state[action.payload.agentId]
            let images = cart.images;
            let index = images.findIndex((item) => item.id === action.payload.image.id);
            if (index !== -1) {
                images.splice(index, 1);
            }
            state[action.payload.agentId] = cart;
        },
        updateImageInQuote: (state, action) => {
            let cart = state[action.payload.agentId];
            let images = cart.images ?? [];

            // Loop through the payload images and update the corresponding images in the state
            action.payload.images?.forEach((updatedImage) => {
                let index = images.findIndex((item) => item.id === updatedImage.id);
                if (index !== -1) {
                    images[index] = updatedImage;
                }
            });
            cart.images = images;


            state[action.payload.agentId] = cart;
        },

        updateQuoteDescription: (state, action) => {
            let cart = state[action.payload.agentId]
            cart.description = action.payload.description
            state[action.payload.agentId] = cart;
        },
        updateQuoteReference: (state, action) => {
            let cart = state[action.payload.agentId]
            cart.reference = action.payload.reference
            state[action.payload.agentId] = cart;
        },
        removeFromCart: (state, action) => {
            let cart = state[action.payload.agentId]
            let node = action.payload.node
            if (!cart) {
                cart = {};
            }
            if (!cart.nodes) {
                cart.nodes = [];
            }
            let nodes = cart.nodes;
            let index = nodes.findIndex((item) => item.id === node.id);
            if (index !== -1) {
                nodes.splice(index, 1);
            }
            //Remove Extra Unit
            if (cart.extraUnit && cart.extraUnit[node.id]) {
                delete cart.extraUnit[node.id];
            }
            //Remove Price Override
            if (cart.priceOverride && cart.priceOverride[node.id]) {
                delete cart.priceOverride[node.id];
            }
            state[action.payload.agentId] = cart;
        },
        clearCart: (state, action) => {
            let agentId = action.payload.agentId;
            state[agentId] = {};
            state[agentId].confirDialogOpen = false;
            state[agentId].description = '';
            state[agentId].reference = '';
            state[agentId].address = '';
            state[agentId].extraUnit = {};
            state[agentId].priceOverride = {};
            state[agentId].images = [];
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    addToCart,
    removeFromCart,
    updatePriceOverride,
    updateExtraUnit,
    attachImageToQuote,
    updateImageInQuote,
    removeImageFromQuote,
    refreshCart,
    updateQuoteReference,
    showDialog,
    hideDialog,
    clearCart,
    setActiveQuote,
    updateQuoteAddress,
    updateQuoteDescription,
    updateQuote
} = cartSlice.actions

export default cartSlice.reducer