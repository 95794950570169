import {createSlice} from '@reduxjs/toolkit'
import {getLoggedInUser, getUserFromLocalStorage} from "../services/AuthService";

export const nodeSlice = createSlice({
    name: 'node',
    initialState: {
        rootNode: {},
        allNodes: [],
        allNodesMap: {}
    },
    reducers: {
        refreshRoot: (state, action) => {
            state.rootNode = action.payload
            let allNodes = []
            allNodes.push({
                id: action.payload.id,
                name: action.payload.name,
            })
            addChildren(action.payload, allNodes)
            state.allNodes = addRandomSuffixToDuplicates(allNodes)
            //ID to node map
            let allNodesMap = {}
            allNodes.forEach((node) => {
                allNodesMap[node.id] = node
            })
            state.allNodesMap = allNodesMap

        },
    },
})

function addRandomSuffixToDuplicates(allNodes) {
    let nameSet = new Set();
    allNodes.forEach(node => {
        let originalName = node.name;
        while(nameSet.has(node.name)) {
            let randomSuffix = '-'+ Math.random().toString(36).substring(7, 15);
            node.name = originalName + randomSuffix;
        }
        nameSet.add(node.name);
    });
    return allNodes;
}

function addChildren(node, allNodes) {
    if (node.children) {
        node.children.forEach((child) => {
            allNodes.push({
                id: child.id,
                name: child.name,
            })
            addChildren(child, allNodes)
        })
    }
}

// Action creators are generated for each case reducer function
export const {
    refreshRoot
} = nodeSlice.actions

export default nodeSlice.reducer