import {getheaders} from "./AuthService";


export function getAllUsers() {
    let requestOptions = {
        method: 'GET', headers: getheaders()
    };

    return fetch(process.env.REACT_APP_BACKEND_URL + "/api/user/all", requestOptions)

}

export function updateOrCreateUser(user) {
    let method = 'PUT'
    if (user.id === 'temp') {
        method = 'POST'
        //remove userid
        delete user.id
    }
    let requestOptions = {
        method: method, headers: getheaders(),
        body: JSON.stringify(user)
    };

    return fetch(process.env.REACT_APP_BACKEND_URL + "/api/user", requestOptions)
}

export function deleteUser(id) {
    let requestOptions = {
        method: 'DELETE', headers: getheaders()
    };

    return fetch(process.env.REACT_APP_BACKEND_URL + "/api/user/" + id, requestOptions)
}