import Paper from "@mui/material/Paper";
import {
    Input,
    InputAdornment,
    Slide,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow
} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {calculateQuotePrice, formatPrice} from "../services/CartService";
import {useDispatch, useSelector} from "react-redux";
import {removeFromCart, updateExtraUnit, updatePriceOverride} from "../reducers/cartSlice";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';



export default function CartTable(props) {
    const [totalPrice, setTotalPrice] = useState(null);
    const [totalPriceString, setTotalPriceString] = useState(null);
    const [maxPrice, setMaxPrice] = useState(null);
    const [maxPriceString, setMaxPriceString] = useState(null);
    const [isBundledPriceUsedMap, setIsBundledPriceUsedMap] = useState({});


    const {selectedUser, readOnly} = props
    const cartItems = useSelector((state) => state.cart[selectedUser.id]?.nodes)
    const priceOverride = useSelector((state) => state.cart[selectedUser.id]?.priceOverride)
    const extraUnit = useSelector((state) => state.cart[selectedUser.id]?.extraUnit)

    const dispatch = useDispatch()

    const [showDescriptions, setShowDescriptions] = useState(cartItems ? cartItems.map(() => true) : []);

    const toggleDescription = (index) => {
        const newShowDescriptions = [...showDescriptions];
        newShowDescriptions[index] = !newShowDescriptions[index];
        setShowDescriptions(newShowDescriptions);
    };


    useEffect(() => {
        if (!cartItems) {
            dispatch(removeFromCart({agentId: selectedUser.id, node: {}}))
        }
        if (!priceOverride) {
            dispatch(updatePriceOverride({agentId: selectedUser.id, priceOverride: {}}))
        }
        if (!extraUnit) {
            dispatch(updateExtraUnit({agentId: selectedUser.id, extraUnit: {}}))
        }
        calculateTotalPrice(cartItems, priceOverride, extraUnit);

    }, [cartItems, priceOverride, extraUnit]);

    function handleInputChange(e, item, state, setState, keyName) {
        const newState = {...state};
        if (e.target.value > 0) {
            newState[item.id] = parseFloat(e.target.value);
        } else {
            newState[item.id] = 0;
        }
        let payload = {agentId: selectedUser.id}
        payload[keyName] = newState
        dispatch(setState(payload));
    }

    function calculateTotalPrice(cartItems, priceOverride, extraUnit) {
        let calcResults = calculateQuotePrice(cartItems, priceOverride, extraUnit)
        setTotalPrice(calcResults.totalPrice)
        setTotalPriceString(calcResults.totalPrice.toLocaleString('en-AU', {
            style: 'currency',
            currency: 'AUD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }));
        setMaxPrice(calcResults.maxPrice)
        setMaxPriceString(calcResults.maxPrice.toLocaleString('en-AU', {
            style: 'currency',
            currency: 'AUD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }));
        setIsBundledPriceUsedMap(calcResults.isBundledPriceUsedNewMap)
    }

    const handleRemoveCart = (item) => {
        dispatch(removeFromCart({agentId: selectedUser.id, node: item}))
    };

    return (

        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell align="left"><b>Name</b></TableCell>
                        <TableCell align="right"><b>Price</b></TableCell>
                        {!readOnly && <TableCell align="right"></TableCell>}
                        {props.override && <TableCell align="right"><b>Override</b></TableCell>}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {cartItems && cartItems.map((item, index) => (

                        <React.Fragment key={index + 'id'}>


                            <TableRow
                                key={index + 'up'}
                            >
                                <Slide direction="right" in={true} mountOnEnter unmountOnExit>
                                    <TableCell>
                                        <span style={{ fontWeight: 600 }}> {/* Adjust the value as needed */}
                                            {item.name}
                                        </span>
                                        {item.description && (
                                            <>
                                                <IconButton size="small" onClick={() => toggleDescription(index)}>
                                                    {showDescriptions[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </IconButton>
                                                <div style={{ marginLeft: '10px', fontStyle: 'italic', display: showDescriptions[index] ? 'block' : 'none' }}>
                                                    {item.description}
                                                </div>
                                            </>
                                        )}
                                    </TableCell>
                                </Slide>
                                <TableCell align="right">
                                    {isBundledPriceUsedMap[item.id] || priceOverride[item.id] > 0 ? (
                                        <span style={{textDecoration: 'line-through', color: 'red'}}>
                                              {formatPrice(item.basePrice)}
                                            {item.upperPrice && item.upperPrice > item.basePrice && ' to ' + formatPrice(item.upperPrice)}
                                            </span>
                                    ) : (
                                        <>
                                            {formatPrice(item.basePrice)}
                                            {item.upperPrice && item.upperPrice > item.basePrice && ' to ' + formatPrice(item.upperPrice)}
                                        </>

                                    )}
                                    {priceOverride[item.id] && priceOverride[item.id] > 0 && (
                                        <span style={{color: 'blue', marginLeft: '0.5rem'}}>
                                              {formatPrice(priceOverride[item.id])}
                                            </span>
                                    )}
                                    {isBundledPriceUsedMap[item.id] && (
                                        <span style={{color: 'green', marginLeft: '0.5rem'}}>
                                              {formatPrice(item.bundlePrice)}
                                            </span>
                                    )}
                                </TableCell>
                                {!readOnly &&
                                    <TableCell align="center" sx={{width: '5px', cursor: 'pointer'}}
                                               onClick={() => handleRemoveCart(item)}>
                                        &#10007;
                                    </TableCell>}
                                {props.override && <TableCell onClick={(e) => {
                                    e.stopPropagation()
                                }}
                                                              align="right">
                                    <Input id="outlined-basic"
                                           startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                           variant="standard"
                                           autoComplete={'off'}
                                           value={priceOverride[item.id]}
                                           onChange={(e) => handleInputChange(e, item, priceOverride, updatePriceOverride, 'priceOverride')}
                                           type={'tel'}
                                           margin={'none'}
                                           size="small"
                                           sx={{width: 80}}
                                    />

                                </TableCell>}
                            </TableRow>
                            {item.extraUnitPrice && item.extraUnitPrice > 0 && (
                                <TableRow
                                    key={index + 'extraUnitPrice'}
                                >
                                    <TableCell> &nbsp;- Extra {item.extraUnitName}</TableCell>
                                    <TableCell align="right" sx={{alignItems: "center"}}>
                                        <Input
                                            variant="standard"
                                            label=""
                                            readOnly={readOnly}
                                            value={extraUnit[item.id]}
                                            onChange={(e) => handleInputChange(e, item, extraUnit, updateExtraUnit, 'extraUnit')}
                                            type="tel"
                                            sx={{
                                                width: 25, '& input': {
                                                    textAlign: 'center',
                                                }
                                            }}

                                        /> x &nbsp;
                                        {formatPrice(item.extraUnitPrice)}
                                    </TableCell>
                                    {!readOnly && <TableCell/>}
                                    {props.override && <TableCell/>}


                                </TableRow>

                            )}
                        </React.Fragment>


                    ))}
                </TableBody>

                <TableFooter>

                    <TableRow>
                        <TableCell>Total Price:</TableCell>
                        {props.override && <TableCell></TableCell>}
                        <TableCell colSpan={2}
                                   align="right"><span style={{color: 'black', fontSize: '14px', fontWeight: 'bolder'}}>
                            {totalPriceString} {maxPrice > 0 && maxPrice > totalPrice && ' - ' + maxPriceString}</span></TableCell>
                    </TableRow>

                </TableFooter>
            </Table>

        </TableContainer>
    )
}