import * as React from 'react';
import {useEffect, useState} from 'react';
import {styled, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {MainListItems, SecondaryListItems} from './ListItems';
import Copyright from "../CopyRight";
import {Route, Routes} from "react-router-dom";
import ChangePassword from "../component/ChangePassword";
import {checkRoles} from "../services/AuthService";
import Checkout from "../checkout/Checkout";
import Quote from "../quote/Quote";
import {defaultTheme} from "../theme";
import TreeView from "../component/TreeView";
import UserManagement from "../component/UserManagement";
import {getAllUsers} from "../services/UserService";
import {History} from "../quote/History";
import {useDispatch, useSelector} from "react-redux";
import {setAllUsers} from "../reducers/userSlice";
import {CircularProgress} from "@mui/material";
import EditNode from "../component/EditNode";


const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);


export default function Dashboard() {
    const [open, setOpen] = useState(
        localStorage.getItem('drawerOpen') === 'true' ? true : false
    );


    const loginUser = useSelector((state) => state.user.loginUser)
    const selectedUser = useSelector((state) => state.user.selectedUser)
    const isLoading = useSelector((state) => state.loading.backend)
    const dispatch = useDispatch()


    useEffect(() => {
        if (checkRoles(loginUser, ['ADMIN', 'STAFF'])) {
            getAllUsers().then(async (response) => {
                if (response.status === 200) {
                    let data = await response.json()
                    dispatch(setAllUsers(data))
                }
            })
        } else {
            dispatch(setAllUsers([selectedUser]))
        }
    }, [loginUser]);


    useEffect(() => {
        localStorage.setItem('drawerOpen', open);
    }, [open]);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    return (
        <ThemeProvider theme={defaultTheme}>

            <Box sx={{display: 'flex'}}>
                <CssBaseline/>

                <Drawer variant="permanent" open={open}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                        }}
                    >
                        {isLoading &&
                            <CircularProgress size={12}/>}
                        {open && 'Welcome  ' + loginUser.name}

                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon/>
                        </IconButton>
                    </Toolbar>
                    <Divider/>
                    <List component="nav">
                        <MainListItems/>
                        <Divider sx={{my: 1}}/>
                        <SecondaryListItems/>
                    </List>
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >


                    <Routes>
                        <Route path="/change-password" element={<ChangePassword/>}/>
                        <Route path="/checkout" element={<Checkout/>}/>
                        {checkRoles(loginUser, ['ADMIN']) &&
                            <>
                                <Route path="/view-data" element={<TreeView/>}/>
                                <Route path="/edit-node" element={<EditNode/>}/></>
                        }
                        {checkRoles(loginUser, ['ADMIN', 'STAFF']) &&
                            <Route path="/user-manage" element={<UserManagement/>}/>}
                        <Route path="/history" element={<History/>}/>

                        <Route path="/quote" element={<Quote/>}/>
                        <Route path="/" element={<Quote/>}/>


                    </Routes>


                    <Copyright sx={{pt: 4}}/>

                </Box>
            </Box>

        </ThemeProvider>
    )
        ;
}
