import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LogoutIcon from '@mui/icons-material/Logout';
import PasswordIcon from '@mui/icons-material/Password';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ForestIcon from '@mui/icons-material/Forest';
import Badge from "@mui/material/Badge";
import TextField from "@mui/material/TextField";
import {Autocomplete} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {checkRoles, TOKEN_KEY} from "../services/AuthService";
import {logoutUser, selectUser} from "../reducers/userSlice";
import {useNavigate} from "react-router-dom";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';


export function MainListItems(props) {
    const isLoading = useSelector((state) => state.loading.backend)
    const allUsers = useSelector((state) => state.user.allUsers)
    const selectedUser = useSelector((state) => state.user.selectedUser)
    const cartItems = useSelector((state) => state.cart[selectedUser.id]?.nodes) || []
    const loginUser = useSelector((state) => state.user.loginUser)

    const navigate = useNavigate();


    const dispatch = useDispatch()


    return (
        <React.Fragment>
            <ListItemButton
                onClick={() => {
                    navigate("/quote")
                }}
                disabled={isLoading}
                selected={window.location.pathname === "/quote"}
            >
                <ListItemIcon>
                    <DashboardIcon/>
                </ListItemIcon>
                <ListItemText primary="Quote"/>
            </ListItemButton>
            <ListItemButton onClick={() => {
                navigate("/checkout")
            }}
                            disabled={isLoading}
                            selected={window.location.pathname === "/checkout"}>
                <ListItemIcon>
                    <Badge badgeContent={cartItems && cartItems.length} color="primary">
                        <ShoppingCartIcon/>
                    </Badge>
                </ListItemIcon>
                <ListItemText primary="Cart"/>
            </ListItemButton>
            <ListItemButton onClick={() => {
                navigate("/history")
            }}
                            disabled={isLoading}
                            selected={window.location.pathname === "/history"}>

                <ListItemIcon>
                    <ManageSearchIcon/>
                </ListItemIcon>
                <ListItemText primary="History"/>
            </ListItemButton>


            {/*<ListItemButton>*/}
            {/*  <ListItemIcon>*/}
            {/*    <BarChartIcon />*/}
            {/*  </ListItemIcon>*/}
            {/*  <ListItemText primary="Reports" />*/}
            {/*</ListItemButton>*/}
            {/*<ListItemButton>*/}
            {/*  <ListItemIcon>*/}
            {/*    <LayersIcon />*/}
            {/*  </ListItemIcon>*/}
            {/*  <ListItemText primary="Integrations" />*/}
            {/*</ListItemButton>*/}
        </React.Fragment>
    )

}

export function SecondaryListItems(props) {
    const isLoading = useSelector((state) => state.loading.backend)
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const loginUser = useSelector((state) => state.user.loginUser)

    function logout() {
        localStorage.removeItem(TOKEN_KEY);
        dispatch(logoutUser())
    }

    return (
        <React.Fragment>
            <ListSubheader component="div" inset>

            </ListSubheader>
            <ListItemButton onClick={() => {
                navigate("/change-password")
            }}
                            disabled={isLoading}
                            selected={window.location.pathname === "/change-password"}
            >

                <ListItemIcon>
                    <PasswordIcon/>
                </ListItemIcon>
                <ListItemText primary="Change password"/>
            </ListItemButton>
            {checkRoles(loginUser, ['ADMIN', 'STAFF']) && (
                <ListItemButton onClick={() => {
                    navigate("/user-manage")
                }}
                                disabled={isLoading}
                                selected={window.location.pathname === "/user-manage"}
                >
                    <ListItemIcon>
                        <ManageAccountsIcon/>
                    </ListItemIcon>
                    <ListItemText primary="User Management"/>
                </ListItemButton>)}
            {checkRoles(loginUser, ['ADMIN']) && (
                <ListItemButton onClick={() => {
                    navigate("/view-data")
                }}
                                disabled={isLoading}
                                selected={window.location.pathname === "/view-data"}
                >
                    <ListItemIcon>
                        <ForestIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Tree View"/>
                </ListItemButton>)}
            <ListItemButton
                onClick={() => logout()}>
                <ListItemIcon>
                    <LogoutIcon/>
                </ListItemIcon>
                <ListItemText primary="Log out"/>
            </ListItemButton>
        </React.Fragment>
    );
}
