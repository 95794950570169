import * as React from 'react';
import {useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
    DataGrid,
    GridActionsCellItem,
    GridRowEditStopReasons,
    GridRowModes,
    GridToolbarContainer,
} from '@mui/x-data-grid';
import {deleteUser, getAllUsers, updateOrCreateUser} from "../services/UserService";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {CircularProgress} from "@mui/material";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import Grid from "@mui/material/Grid";
import {useDispatch, useSelector} from "react-redux";
import {startLoading, stopLoading} from "../reducers/loadingSlice";

const roles = ['Market', 'Finance', 'Development'];


function EditToolbar(props) {
    const {setFilteredRows, setRowModesModel} = props;


    const handleClick = () => {
        const id = 'temp'
        setFilteredRows((oldRows) => [...oldRows, {id, name: '', role: 'AGENT', isNew: true}]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: {mode: GridRowModes.Edit, fieldToFocus: 'name'},
        }));
    };

    return (
        <GridToolbarContainer>
            <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Button color="primary" startIcon={<AddIcon/>} onClick={handleClick}>
                        Add record
                    </Button>
                </Grid>
                <Grid item>
                    <Grid container alignItems="center">
                        <Grid item>
                            <SearchIcon sx={{fontSize: 35, mt: 3}}/>

                        </Grid>
                        <Grid item>
                            <TextField
                                id="search-basic"
                                label="Search"
                                autoComplete={false}
                                defaultValue={''}
                                onChange={(e) => {
                                    props.handleSearchTextChange(e);
                                }}
                                variant="standard"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </GridToolbarContainer>
    );
}

export default function UserManagement() {
    const [rows, setRows] = React.useState([]);
    const [filteredRows, setFilteredRows] = React.useState([]);
    const [message, setMessage] = React.useState()
    const [searchText, setSearchText] = React.useState('')
    const [rowModesModel, setRowModesModel] = React.useState({});
    const isLoading = useSelector((state) => state.loading.backend)
    const dispatch = useDispatch()

    function reloadUsers() {
        dispatch(startLoading())
        getAllUsers().then(async (r) => {
            if (r.status === 200) {
                let rdata = await r.json()
                setRows(rdata)
                setFilteredRows(rdata)
            } else {
                console.log("Error fetch user data: " + r.status);
            }


        }).finally(() => {
            dispatch(stopLoading())
        });
    }

    useEffect(() => {
        reloadUsers()
    }, []);

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.Edit}});
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}});
    };

    const handleDeleteClick = (id) => () => {
        dispatch(startLoading())
        deleteUser(id).then(async (r) => {
            if (r.status === 200) {
                setMessage("Deleted successfully!")
            } else {
                let text = await r.json()
                setMessage("Error: " + text.message);
            }
        }).finally(() => {
            reloadUsers()
        });
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = (newRow) => {
        const updatedRow = {...newRow, isNew: false};
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        dispatch(startLoading())
        let cloneRow = {...updatedRow}

        updateOrCreateUser(cloneRow).then(async (r) => {
            if (r.status === 200) {
                setMessage("Saved successfully!")

            } else {
                let text = await r.json()
                setMessage("Error: " + text.message);
            }
        }).finally(() => {
            reloadUsers()
        });
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const columns = [
        {field: 'userName', headerName: 'User Name', width: 180, editable: true},
        {field: 'name', headerName: 'Name', width: 180, editable: true},
        {field: 'email', headerName: 'Email', width: 180, editable: true},
        {field: 'phone', headerName: 'Phone', width: 180, editable: true},
        {field: 'password', headerName: 'Password(No change if blank)', width: 250, editable: true},

        {
            field: 'role',
            headerName: 'Department',

            editable: true,
            type: 'singleSelect',
            valueOptions: ['ADMIN', 'STAFF', 'AGENT'],
        },
        {field: 'commission', headerName: 'Commission(%)', type: 'number', width: 150, editable: true},

        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({id}) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon/>}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            disabled={isLoading}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon/>}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                            disabled={isLoading}
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon/>}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                        disabled={isLoading}
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon/>}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                        disabled={isLoading}
                    />,
                ];
            },
        },
    ];
    const yellowHighlight = {
        fontSize: '1.5rem',
        padding: '0.2em 0.5em',
        borderRadius: '4px',
    };

    function handleSearchTextChange(e) {
        setSearchText(e.target.value)
        if (e.target.value === '') {
            setFilteredRows(rows)
        } else {
            setFilteredRows(rows.filter((row) => row.userName?.includes(e.target.value) || row.email?.includes(e.target.value) || row.phone?.includes(e.target.value)))
        }
    }

    return (
        <Container maxWidth="xl" sx={{my: 2}}>
            <Typography
                component="h3"
                variant="h4"
                align="center"
                color="primary"


                sx={{
                    pt: 4, mb: 1, pb: 1
                }}
            >User Management</Typography>
            <Typography component="h4" align="center" style={{ ...yellowHighlight }}>
                {isLoading ? <CircularProgress size={24} /> : message}
            </Typography>
            <Box
                sx={{
                    height: '100%',
                    width: '100%',
                    '& .actions': {
                        color: 'text.secondary',
                    },
                    '& .textPrimary': {
                        color: 'text.primary',
                    },
                }}
            >
                <DataGrid
                    rows={filteredRows}
                    columns={columns}
                    editMode="row"
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    slots={{
                        toolbar: EditToolbar,
                    }}
                    slotProps={{
                        toolbar: {setFilteredRows, setRowModesModel, handleSearchTextChange},
                    }}
                />
            </Box>
        </Container>
    );
}