import * as React from "react";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {populateImageUrl} from "../services/ImageService";
import {updateImageInQuote} from "../reducers/cartSlice";
import ImageComponent from "./ImageComponent";


export default function ImageGallary(props) {
    const {selectedUser, readOnly} = props
    const images = useSelector((state) => state.cart[selectedUser.id]?.images)
    const isLoading = useSelector((state) => state.loading.backend)

    const dispatch = useDispatch()

    function isUrlExpired(url) {
        if (!url) return true;
        const params = new URLSearchParams(url.split('?')[1]);
        const expireSeconds = parseInt(params.get('X-Goog-Expires'), 10);
        const startTime = params.get('X-Goog-Date');

        const year = startTime.slice(0, 4);
        const month = startTime.slice(4, 6);
        const day = startTime.slice(6, 8);
        const hour = startTime.slice(9, 11);
        const minute = startTime.slice(11, 13);
        const second = startTime.slice(13, 15);

        const isoString = `${year}-${month}-${day}T${hour}:${minute}:${second}Z`;
        const startDate = new Date(isoString);

        //Start time plus expire seconds
        startDate.setSeconds(startDate.getSeconds() + expireSeconds);
        return new Date() > startDate;
    }

    useEffect(() => {
        let changedImages = []
        images?.forEach((image) => {
            if (isUrlExpired(image.downloadUrl)) {
                changedImages.push({
                    ...image,
                    downloadUrl: undefined,
                    thumbnailUrl: undefined
                })
            }
        })
        if (changedImages.length > 0) {
            dispatch(updateImageInQuote({
                agentId: selectedUser.id,
                images: changedImages
            }))
        }
    }, [selectedUser])


    useEffect(() => {
        if (images === undefined) return;
        const imagesToUpdate = images.filter((image) => !image.downloadUrl);

        const idsToUpdate = imagesToUpdate.map((image) => image.id);
        if (idsToUpdate.length === 0) return;
        populateImageUrl(idsToUpdate).then(async (imagesResponse) => {
            if (imagesResponse.status === 200) {
                imagesResponse.json().then((data) => {
                    dispatch(updateImageInQuote({
                        agentId: selectedUser.id,
                        images: data
                    }))
                })
            }
        })

    }, [images])

    const imageSize = 100

    return (
        <div style={{display: 'flex', flexWrap: 'wrap', gap: '20px', marginTop: '10px'}}>
            {images && images.map((item, index) => (
                <ImageComponent
                    key={index + item.id}
                    readOnly={readOnly}
                    selectedUser={selectedUser}
                    item={item}
                    alt={item.title}
                    loading="lazy"
                    width={imageSize}
                    height={imageSize}
                />

            ))}
        </div>
    )
}
