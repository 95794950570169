import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import {v4 as uuidv4} from 'uuid';
const styles = StyleSheet.create({

    titleContainer:{
        flexDirection: 'row',
        marginTop: 12
    },
    reportTitle:{
        fontSize: 12,
        textAlign: 'center',
        textTransform: 'uppercase',
    }
});


const InvoiceThankYouMsg = () => (
    <View key={uuidv4()} style={styles.titleContainer}>
        <Text style={styles.reportTitle}>Thank you for your business</Text>
    </View>
);

export default InvoiceThankYouMsg