import * as React from 'react';
import {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import ShoppingCart from "../quote/ShoppingCart";
import Container from "@mui/material/Container";
import QuoteSummary from "../quote/QuoteSummary";
import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    Tooltip
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {clearCart, hideDialog} from "../reducers/cartSlice";
import {useNavigate} from "react-router-dom";
import {selectUser} from "../reducers/userSlice";
import TextField from "@mui/material/TextField";
import {checkRoles} from "../services/AuthService";
import {UserSelector} from "../component/UserSelector";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function Checkout(props) {

    const selectedUser = useSelector((state) => state.user.selectedUser)
    const open = useSelector((state) => state.cart[selectedUser.id]?.confirDialogOpen)
    const [adminAlert, setAdminAlert] = useState(false);
    const navigate = useNavigate()
    const isLoading = useSelector((state) => state.loading.backend)
    const allUsers = useSelector((state) => state.user.allUsers)
    const loginUser = useSelector((state) => state.user.loginUser)

    const dispatch = useDispatch()

    useEffect(() => {
        if (!open) {
            dispatch(hideDialog({agentId: selectedUser.id}))
        }
    }, [open])

    function handleCloseButton() {
        dispatch(hideDialog({agentId: selectedUser.id}))
        if (checkRoles(loginUser, ['ADMIN', 'STAFF'])) {
            //If it is Staff or admin
            setAdminAlert(true)
        } else {
            navigate('/quote')
        }
    }

    function handleStartNewQuote() {
        dispatch(hideDialog({agentId: selectedUser.id}))
        dispatch(clearCart({agentId: selectedUser.id}))
        if (checkRoles(loginUser, ['ADMIN', 'STAFF'])) {
            setAdminAlert(true)
        } else {
            navigate('/quote')
        }


    }

    return (
        <Container maxWidth="100%" sx={{mt: 1, mb: 4, ml: 0}}>
            <Grid container spacing={0.5}>
                <Grid item xs={12} md={12} lg={8}>

                    <ShoppingCart
                        override={!checkRoles(loginUser, ['AGENT'])}
                        readOnly={false}
                    />

                </Grid>
                <Grid item xs={12} md={12} lg={4} style={{position: 'sticky', top: '0px', height: '100%'}}>
                    <UserSelector/>
                    <QuoteSummary
                        selectedUser={selectedUser}/>

                </Grid>
            </Grid>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseButton}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <QuoteSummary
                        selectedUser={selectedUser}/>
                    <DialogContentText>
                        Please copy and send above messages to admin for further work scheduling.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Tooltip title={"The Shopping Cart will NOT be cleared."}>
                        <Button onClick={handleCloseButton}>Close</Button>
                    </Tooltip>
                    <Tooltip title={"The Shopping Cart will be cleared and Start a new quote"}>
                        <Button onClick={handleStartNewQuote}>Start New Quote</Button>
                    </Tooltip>
                </DialogActions>
            </Dialog>


            <Dialog
                open={adminAlert}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseButton}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>Please select a user before start next quote</DialogTitle>
                <DialogContent>
                    <Container sx={{height: '500px', px: 5, py: 5}}>
                        <Autocomplete
                            sx={{height: '500px', px: 5, my: 5}}
                            disablePortal
                            id="combo-box-demo"
                            disableClearable
                            disabled={isLoading}
                            options={allUsers}
                            value={selectedUser}
                            onChange={(event, newValue) => {
                                dispatch(selectUser(newValue))
                                setAdminAlert(false)
                            }}
                            getOptionLabel={(user) => user.name} // Specify the property to display in the autocomplete
                            isOptionEqualToValue={(option, value) => option && option.id === value.id}
                            sx={{width: 300}}
                            renderInput={(params) =>
                                <TextField {...params} InputLabelProps={{
                                    shrink: true,
                                }} label="Acting User"/>}
                            renderOption={(props, user) => (
                                <li {...props}>
                                    {/* Render additional information about the user if needed */}
                                    {user.name}
                                </li>
                            )}
                        />
                    </Container>
                    <DialogActions>
                        <Button onClick={() => setAdminAlert(false)}>Confirm to use same user</Button>

                    </DialogActions>
                </DialogContent>
            </Dialog>

        </Container>

    );
}
