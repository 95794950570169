import jwt_decode from "jwt-decode";


export const TOKEN_KEY = '4240951135';

export function getUid() {
    let jwtToken = localStorage.getItem(TOKEN_KEY)
    if (jwtToken == null) {
        return ""
    }
    let decodedToken = jwt_decode(jwtToken);

    return decodedToken?.uid
}

export function getLoggedInUser() {
    let jwtToken = localStorage.getItem(TOKEN_KEY)
    if (jwtToken == null) {
        return ""
    }
    let decodedToken = jwt_decode(jwtToken);

    return {
        userName: decodedToken?.sub,
        role: decodedToken?.roles[0],
        id: decodedToken?.uid}
}

export function getheaders() {
    checkSession()
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let cosToken = localStorage.getItem(TOKEN_KEY)
    if (!isValidLogin()) {
        logout()
    }
    myHeaders.append("Authorization", "Bearer " + cosToken)
    return myHeaders;
}

export async function login(username, password) {

    let loginheaders = new Headers();
    loginheaders.append("Content-Type", "application/json");

    let requestOptions = {
        method: 'POST', headers: loginheaders,
        body: JSON.stringify({
            username: username,
            password: password
        })
    };

    return fetch(process.env.REACT_APP_BACKEND_URL + "/api/login", requestOptions)
}

export async function resetPassword(password) {

    let requestOptions = {
        method: 'POST', headers: getheaders(),
        body: JSON.stringify({
            password: password
        })
    };

    return fetch(process.env.REACT_APP_BACKEND_URL + "/api/user/resetPassword", requestOptions)
}

export function logout() {
    localStorage.removeItem(TOKEN_KEY);
    window.location.href = "/";
}

export function checkSession() {
    try {
        let cosToken = localStorage.getItem(TOKEN_KEY)
        if (cosToken == null) {
            logout()
        }
        let decodedToken = jwt_decode(cosToken);

        let dateNow = new Date();

        if (decodedToken.exp < (dateNow.getTime() / 1000)) {
            logout()
        }
    } catch (e) {
        logout()
    }
    return true
}

export function checkRoles(loginUser, rolesToCheck) {
    if (loginUser == null) {
        return false
    }
    let loginUserRoles = loginUser.roles;
    // Loop through each role in the rolesToCheck array
    for (const roleToCheck of rolesToCheck) {
        // Check if the roleToCheck exists in the loginUserRoles array
        if (loginUserRoles.includes(roleToCheck)) {
            // If a match is found, return true
            return true;
        }
    }
    // If no match is found, return false
    return false;
}

export function isValidLogin() {
    try {
        let cosToken = localStorage.getItem(TOKEN_KEY)
        if (cosToken == null) {
            return false
        }
        let decodedToken = jwt_decode(cosToken);

        let dateNow = new Date();
        if (decodedToken.exp < (dateNow.getTime() / 1000)) {
            return false
        }
    } catch (e) {
        return false
    }
    return true
}

export function getUserFromLocalStorage() {
    let cosToken = localStorage.getItem(TOKEN_KEY)
    if (cosToken == null) {
        return null
    }
    let decodedToken = jwt_decode(cosToken);

    let dateNow = new Date();
    if (decodedToken.exp < (dateNow.getTime() / 1000)) {
        return null
    }
    return decodedToken
}