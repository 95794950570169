import {getheaders} from "./AuthService";
import {v4 as uuidv4} from 'uuid';


export const ROOT_NODE_ID = "2828781750"

export async function getNode(id, fulllayer) {

    let requestOptions = {
        method: 'GET', headers: getheaders()
    };

    if (fulllayer) {
        return fetch(process.env.REACT_APP_BACKEND_URL + "/api/node/" + id + "?full-layer=" + fulllayer, requestOptions)
    }

    return fetch(process.env.REACT_APP_BACKEND_URL + "/api/node/" + id, requestOptions)
}

function getPrices(nodeIDs, agentId) {

    let requestOptions = {
        method: 'POST', headers: getheaders(), body: JSON.stringify(nodeIDs)
    };

    return fetch(process.env.REACT_APP_BACKEND_URL + "/api/price/search/" + agentId, requestOptions)
}

export function searchNode(query, agentId) {
    let requestOptions = {
        method: 'GET', headers: getheaders()
    };
    return fetch(process.env.REACT_APP_BACKEND_URL + "/api/node/search?q=" + query, requestOptions).then(async (response) => {
        if (response.status === 200) {
            let data = await response.json()
            await refreshPrice({"children": data}, agentId)
            return data
        } else {
            console.error(response.text)
            return null
        }
    });
}

export function getAllLeafNode(agentId) {
    let requestOptions = {
        method: 'GET', headers: getheaders()
    };
    return fetch(process.env.REACT_APP_BACKEND_URL + "/api/node/leaf", requestOptions).then(async (response) => {
        if (response.status === 200) {
            let data = await response.json()
            await refreshPrice({"children": data}, agentId)
            return data
        } else {
            console.error(response.text)
            return null
        }
    });
}

export async function updatePrice(agentId, nodeData) {
    let reqbody = {
        "agentId": agentId,
        "nodeId": nodeData.id,
        "basePrice": nodeData.basePrice,
        "upperPrice": nodeData.upperPrice,
        "bundlePrice": nodeData.bundlePrice,
        "discountPrice": nodeData.discountPrice,
        "extraUnitPrice": nodeData.extraUnitPrice,
        "extraUnitName": nodeData.extraUnitName,
    }
    let requestOptions = {
        method: 'POST', headers: getheaders(), redirect: 'follow', body: JSON.stringify(reqbody)
    };
    return fetch(process.env.REACT_APP_BACKEND_URL + "/api/price/" + agentId, requestOptions)
}

export function refreshNodes(nodeId, agentId, setNodeData, fulllayer) {
    return getNode(nodeId, fulllayer).then(async (response) => {
        if (response.status === 200) {
            let data = await response.json()
            await refreshPrice(data, agentId)
            setNodeData(data)
        } else {
            setNodeData({"children": []})
        }
    }).catch((error) => {
        console.error(error)
        setNodeData({"children": []})
    });
}

export function addNode(parentId, nodeName) {
    let reqbody = {
        "parentId": parentId,
        "name": nodeName,
        // UUID generate
        "id": uuidv4()
    }
    let requestOptions = {
        method: 'POST', headers: getheaders(), redirect: 'follow', body: JSON.stringify(reqbody)
    };
    return fetch(process.env.REACT_APP_BACKEND_URL + "/api/node", requestOptions)

}

export function createAdhocNode(name, agentId, basePrice) {
    let reqbody = {
        "name": name,
        "agentId": agentId,
        "basePrice": basePrice,
    }

    let requestOptions = {
        method: 'POST', headers: getheaders(), redirect: 'follow', body: JSON.stringify(reqbody)
    }
    return fetch(process.env.REACT_APP_BACKEND_URL + "/api/node/adhoc", requestOptions)
}

export function updateNode(nodeData) {
    let requestOptions = {
        method: 'PUT', headers: getheaders(), redirect: 'follow', body: JSON.stringify(nodeData)
    };
    return fetch(process.env.REACT_APP_BACKEND_URL + "/api/node", requestOptions)
}

export function deleteNode(nodeId) {
    let requestOptions = {
        method: 'DELETE', headers: getheaders(), redirect: 'follow'
    };
    return fetch(process.env.REACT_APP_BACKEND_URL + "/api/node/" + nodeId, requestOptions)
}

export async function refreshPrice(data, agentId) {
    let leafNodes = [data]
    if (data.children !== undefined) {
        leafNodes = [...data.children, data]
    }

    let leafNodeIds = leafNodes.map((tier) => {
        return tier.id
    })
    return getPrices(leafNodeIds, agentId).then(async (response) => {
        if (response.status === 200) {
            let priceMap = await response.json()
            leafNodes.forEach((leafNode) => {
                if (priceMap[leafNode.id] !== undefined) {
                    leafNode.priceId = priceMap[leafNode.id].id
                    leafNode.basePrice = priceMap[leafNode.id].basePrice
                    leafNode.upperPrice = priceMap[leafNode.id].upperPrice
                    leafNode.discountPrice = priceMap[leafNode.id].discountPrice
                    leafNode.bundlePrice = priceMap[leafNode.id].bundlePrice
                    leafNode.extraUnitPrice = priceMap[leafNode.id].extraUnitPrice
                    leafNode.extraUnitName = priceMap[leafNode.id].extraUnitName
                }
            })
        }
    });
}