import * as React from 'react';
import {startLoading} from "../reducers/loadingSlice";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import {v4 as uuidv4} from 'uuid';

export default function ItemStack(props) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const breadcrumbs = props.nodeData.parentPathInfo && props.nodeData.parentPathInfo.slice().reverse().map((item, index) => {
        const iconArray = Array.from({length: index}, (_, i) => i);

        return (
            // <Link underline="hover"
            //       sx={{cursor: 'pointer'}}
            //
            //       color="inherit"

            // >
            <ListItem disablePadding sx={{}} key={uuidv4()}>
                <ListItemButton sx={{
                    mt: 1,
                    boxShadow: '0 2px 2px rgba(0, 0, 0, 0.3)',
                    borderRadius: '5px',
                    borderColor: (theme) => theme.palette.primary.main,
                    backgroundColor: 'rgba(255, 152, 0, 0.08)',

                }}

                                onClick={() => {
                                    const searchParams = new URLSearchParams(window.location.search);
                                    let id = searchParams.get('id');
                                    if (id === item.id) {
                                        return
                                    }
                                    dispatch(startLoading())
                                    navigate(`?id=${item.id}`)
                                }}>
                    <ListItemIcon>
                        {[0, ...iconArray].map((number) => (
                            <ArrowForwardIosRoundedIcon key={uuidv4()}/>
                        ))}
                    </ListItemIcon>
                    <ListItemText inset primary={item.name}/>
                </ListItemButton>
            </ListItem>

            // </Link>
        )
    })

    return (

        <List dense={true}>
            {breadcrumbs}
        </List>

    );

}