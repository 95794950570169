import React from 'react';
import {StyleSheet, Text, View} from '@react-pdf/renderer';
import {v4 as uuidv4} from 'uuid';

const borderColor = '#000000'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 24,
        fontSize: 12,
        fontWeight: 600,
    },
    blankSpace: {
        width: '50%',
        extAlign: 'right',
    },
    totalDescription: {
        borderBottom: '0.8 solid #000000',
        width: '35%',
        textAlign: 'right',
    },
    totalTotal: {
        borderBottom: '0.8 solid #000000',
        width: '25%',
        textAlign: 'right',
    },
    description: {
        width: '35%',
        textAlign: 'right',
    },
    total: {
        width: '25%',
        textAlign: 'right',
    },
});

const InvoiceTableFooter = ({invoice}) => {
    const totalPrice = invoice.totalPrice;
    const maxPrice = invoice.maxPrice;
    const totalWithGst = totalPrice * 1.1;
    const maxWithGst = maxPrice * 1.1;
    const totalgst = totalPrice * 0.1;
    const maxgst = maxPrice * 0.1;

    return (
        <>
            <View key={uuidv4()} style={styles.row}>
                <Text style={styles.blankSpace}></Text>
                <Text style={styles.description}>Total Price:</Text>
                <Text style={styles.total}>
                    {maxPrice > 0 && maxPrice > totalPrice ?
                        `${Number.parseFloat(totalPrice).toFixed(2)} - ${Number.parseFloat(maxPrice).toFixed(2)}`
                        :
                        Number.parseFloat(totalPrice).toFixed(2)
                    }
                </Text>
            </View>
            <View key={uuidv4()} style={styles.row}>
                <Text style={styles.blankSpace}></Text>
                <Text style={styles.totalDescription}>Total Gst(10%):</Text>
                <Text style={styles.totalTotal}>
                    {maxPrice > 0 && maxPrice > totalPrice ?
                        `${Number.parseFloat(totalgst).toFixed(2)} - ${Number.parseFloat(maxgst).toFixed(2)}`
                        :
                        Number.parseFloat(totalgst).toFixed(2)
                    }
                </Text>
            </View>
            <View key={uuidv4()} style={styles.row}>
                <Text style={styles.blankSpace}></Text>
                <Text style={styles.totalDescription}>Total AUD:</Text>
                <Text style={styles.totalTotal}>
                    {maxPrice > 0 && maxPrice > totalPrice ?
                        `${Number.parseFloat(totalWithGst).toFixed(2)} - ${Number.parseFloat(maxWithGst).toFixed(2)}`
                        :
                        Number.parseFloat(totalWithGst).toFixed(2)
                    }
                </Text>
            </View>
        </>
    )
};




export default InvoiceTableFooter
