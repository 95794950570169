import * as React from "react";
import './TreeView.css';
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import SaveIcon from '@mui/icons-material/Save';
import {CircularProgress, TextField} from "@mui/material";
import LockResetIcon from '@mui/icons-material/LockReset';
import {addNode, refreshNodes} from "../services/NodeService";
import {useDispatch, useSelector} from "react-redux";
import {startLoading, stopLoading} from "../reducers/loadingSlice";


export default function AddNode(props) {

    const [newNodeName, setNewNodeName] = React.useState('');
    const isLoading = useSelector((state) => state.loading.backend)
    const selectedUser = useSelector((state) => state.user.selectedUser)

    const dispatch = useDispatch()


    function handleAddNode(newNodeName) {
        dispatch(startLoading())
        addNode(props.nodeData.id, newNodeName).then(async (response) => {
            if (response.status === 200) {
                return refreshNodes(props.nodeData.id, selectedUser.id, props.setNodeData, false)
            } else {
                console.error(response.text())
            }
        }).finally(() => dispatch(stopLoading()));
    }


    return (<ListItem
        sx={{
            border: 1,
        }}
        key={"newNode"}
        secondaryAction={<>
            <IconButton edge="end" aria-label="save"
                        onClick={() => handleAddNode(newNodeName)}
                        disabled={isLoading}>
                {isLoading ? (<CircularProgress size={24}/>) : <SaveIcon/>}

            </IconButton>
            <IconButton edge="end"
                        onClick={() => setNewNodeName('')}

                        aria-label="delete">
                <LockResetIcon/>
            </IconButton>
        </>}
    >
        <ListItemText
            primary={<>
                <TextField
                    label="New Item Name"
                    value={newNodeName}
                    onChange={(event) => setNewNodeName(event.target.value)}
                    required
                    InputLabelProps={{shrink: true}}
                    id="Name"

                />
            </>}

            secondary={


                <>
                    Adding a new item, save and view the items
                </>}
        />

    </ListItem>)
}