import {createTheme} from "@mui/material/styles";

export const defaultTheme = createTheme({
    fontFamily: [
        "Noto Sans SC",
    ],
    palette: {
        primary: {
            main: '#FF9800',
        },
        secondary: {
            main: '#F57C00',
        },
    },
    overrides: {
        MuiOutlinedInput: {
            root: {
                '& $notchedOutline': {
                    borderColor: '#F57C00',
                },
                '&:hover $notchedOutline': {
                    borderColor: '#FF9800',
                },
                '&$focused $notchedOutline': {
                    borderColor: '#FF9800',
                },
            },
        },
    },
});