import * as React from 'react';
import {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import {getAllLeafNode, refreshNodes, ROOT_NODE_ID} from "../services/NodeService";
import {useNavigate} from "react-router-dom";
import PriceList from "../component/PriceList";

import {
    Autocomplete, Button,
    CircularProgress,
    Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle,
    FormControlLabel,
    InputAdornment,
    Switch
} from "@mui/material";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import AddNode from "../component/AddNode";
import Highlighter from "react-highlight-words";
import {useDispatch, useSelector} from "react-redux";
import {startLoading, stopLoading} from "../reducers/loadingSlice";
import {addToCart, updateExtraUnit, updateQuoteDescription} from "../reducers/cartSlice";
import {checkRoles} from "../services/AuthService";
import ItemStack from "../component/ItemStack";
import SearchIcon from "@mui/icons-material/Search";
import {toggleExpressMode} from "../reducers/userSlice";


export default function Pricing(props) {
    let navigate = useNavigate();
    const [addChildFlag, setAddChildFlag] = useState(false);
    const isLoading = useSelector((state) => state.loading.backend)
    const selectedUser = useSelector((state) => state.user.selectedUser)
    const loginUser = useSelector((state) => state.user.loginUser)
    const description = useSelector((state) => state.cart[selectedUser.id]?.description)
    const extraUnit = useSelector((state) => state.cart[selectedUser.id]?.extraUnit)


    const [open, setOpen] = useState(false);
    const [selectedTier, setSelectedTier] = useState(null);



    const dispatch = useDispatch()
    const [leafNodes, setLeafNodes] = useState([])
    const [message, setMessage] = useState()

    const [searchText, setSearchText] = useState('')
    const [nodeData, setNodeData] = useState({
        "children": []
    })

    const expressMode = useSelector((state) => state.user.expressMode)

    const handleSearchChange = (event, value) => {
        //Add value to node data
        if (value && value.id) {
            setSearchText(value.name)
            if (value.parentId) {
                navigate(`?id=${value.parentId}`)
            }
        }

    };


    useEffect(() => {
        dispatch(startLoading())
        const searchParams = new URLSearchParams(window.location.search);
        setSearchText('')
        let id = searchParams.get('id');
        if (id === null) {
            id = ROOT_NODE_ID
        }
        if (checkRoles(loginUser, ['AGENT'])) {
            setLeafNodes([])
        } else {
            getAllLeafNode(selectedUser.id).then((r) => {
                //Remove duplicate
                const uniqueArray = r.filter((item, index, self) => {
                    const foundIndex = self.findIndex((element) => element.name === item.name);
                    return index === foundIndex;
                });
                setLeafNodes(uniqueArray)
            }).catch((error) => {
                console.error(error)
                setMessage("Failed to load data, please retry")
            });
        }
        let fullLayer = 'false';
        if (expressMode && id !== ROOT_NODE_ID) {
            fullLayer = 'express'
        }

        refreshNodes(id, selectedUser.id, setNodeData, fullLayer).finally(() => dispatch(stopLoading()));
    }, [window.location.search, selectedUser, expressMode]);

    function handleInputChange(e, item, state, setState, keyName) {

        const newState = {...state};
        if (e.target.value > 0) {
            newState[item.id] = parseFloat(e.target.value);
        } else {
            newState[item.id] = 0;
        }
        let payload = {agentId: selectedUser.id}
        payload[keyName] = newState
        dispatch(setState(payload));
    }

    const handleCardAction = (tier) => {
        setSelectedTier(tier)
        if (tier.extraUnitPrice > 0) {
            setOpen(true);
            return;
        }

        if (tier.leaf) {
            dispatch(addToCart(
                {
                    agentId: selectedUser.id,
                    node: tier
                }))

        } else {
            //Drill down
            dispatch(startLoading())
            navigate(`?id=${tier.id}`);
        }
    };

    const handleExtraUnitClose = () => {
        setOpen(false);
    };

    const handleExtraUnitSave = (tier) => {
        setOpen(false);
        dispatch(addToCart(
            {
                agentId: selectedUser.id,
                node: tier
            }))

        if (!tier.leaf) {
            dispatch(startLoading())
            navigate(`?id=${tier.id}`);
        }
    };


    const handleEditClick = (tier) => {
        dispatch(startLoading())
        navigate(`/edit-node?id=${tier.id}`);
    };

    const handleQuoteNotesChange = (event) => {
        dispatch(updateQuoteDescription({agentId: selectedUser.id, description: event.target.value}))
    }


    return (<div>

        <Container maxWidth="xl" sx={{my: 1, display: 'flex', alignItems: 'center'}}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={8} sx={{textAlign: 'left'}}>
                    <ItemStack nodeData={nodeData}></ItemStack>
                    {checkRoles(loginUser, ['ADMIN', 'STAFF']) && (
                        <FormControlLabel
                            control={<Switch checked={expressMode}
                                             onChange={(event) => dispatch(toggleExpressMode())}
                            />}
                            label="Express Mode"
                            placement="start"
                        />)}
                </Grid>
                <Grid item xs={4}>


                    <Autocomplete
                        disablePortal
                        freeSolo
                        disableClearable
                        autoHighlight
                        id="combo-box-demo"
                        options={leafNodes}
                        disabled={isLoading}
                        onChange={handleSearchChange}
                        onInputChange={(event, value) => {
                            setSearchText(value)
                        }}
                        getOptionLabel={(node) => node.name} // Specify the property to display in the autocomplete
                        isOptionEqualToValue={(option, value) => option && option.id === value.id}
                        fullWidth
                        renderInput={(params) =>
                            <TextField {...params}
                                       autoFocus
                                       variant="standard"
                                       type={"search"}
                                       InputProps={{
                                           ...params.InputProps,

                                           startAdornment: (
                                               <InputAdornment position="start">
                                                   <SearchIcon/>
                                               </InputAdornment>
                                           ),
                                       }} label="Search"/>}
                        renderOption={(props, node) => {
                            return (
                                <li {...props}>
                                    <Highlighter
                                        highlightStyle={{fontWeight: "900", backgroundColor: "transparent"}}
                                        searchWords={[searchText]}
                                        autoEscape={true}
                                        textToHighlight={node.name ?? ""}
                                    />
                                </li>
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={4}>

                </Grid>
            </Grid>
        </Container>


        <Container maxWidth="xl" component="main" sx={{
            mt: 3,
        }}>

            <List dense={true}>
                {!nodeData || !nodeData.children || nodeData.children.length === 0 ?
                    <CircularProgress size={50}/> : null}
                {nodeData.children && nodeData.children.filter(
                    (tier) => tier.name?.toLowerCase().includes(searchText.toLowerCase()) || tier.searchResult
                ).map((tier) => (<Grid
                    item
                    key={tier.id}
                    xs={12}
                    align={'center'}

                >
                    <PriceList pricecard={tier}
                               key={tier.id}
                               handleCardAction={handleCardAction}
                               handleEditClick={handleEditClick}
                    />

                </Grid>))}
            </List>
            {props.heading && <>
                <TextField
                    label="Quote Notes"
                    multiline
                    rows={3}
                    variant="outlined"
                    fullWidth
                    value={description}
                    onChange={handleQuoteNotesChange}
                    InputLabelProps={{shrink: true}} // To display the label above the TextField
                    placeholder="You can type your special requirement here" // The hint text
                    sx={{mt: 3}}
                />

                <Dialog open={open} onClose={handleExtraUnitClose}>
                    <DialogTitle>Enter Extra Unit</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            We need you to input the number of {selectedTier?.extraUnitName} for this item.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            label={selectedTier?.extraUnitName + "(s)"}
                            type="tel"
                            fullWidth
                            value={selectedTier ? extraUnit[selectedTier.id] : undefined}
                            onChange={(e) => {
                                const value = parseInt(e.target.value, 10);
                                if (value >= 1) {
                                    handleInputChange(e, selectedTier, extraUnit, updateExtraUnit, 'extraUnit');
                                }
                            }}                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleExtraUnitClose}>Cancel</Button>
                        <Button onClick={() => handleExtraUnitSave(selectedTier)}>Save</Button>
                    </DialogActions>
                </Dialog>
                <List>
                    {addChildFlag && <AddNode

                        nodeData={nodeData}
                        setNodeData={setNodeData}/>}
                </List></>}

        </Container>

    </div>);
}
