import * as React from 'react';
import {useEffect, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import {ThemeProvider} from '@mui/material/styles';
import {getLoggedInUser, getUserFromLocalStorage, login, TOKEN_KEY} from "../services/AuthService";
import {CircularProgress} from "@mui/material";
import Copyright from "../CopyRight";
import {defaultTheme} from "../theme";
import {useDispatch, useSelector} from "react-redux";
import {startLoading, stopLoading} from "../reducers/loadingSlice";
import {loginUser, selectUser} from "../reducers/userSlice";
import {useNavigate} from "react-router-dom";

// TODO remove, this demo shouldn't need to reset the theme.

export default function SignInSide() {

    const [message, setMessage] = useState()

    const isLoading = useSelector((state) => state.loading.backend)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(stopLoading())
    }, [])


    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(startLoading())
        const data = new FormData(event.currentTarget);
        let username = data.get('username')
        let password = data.get('password')

        login(username, password).then(response => {
            if (response.status === 200) {
                response.json().then(result => {
                    localStorage.setItem(TOKEN_KEY, result.access_token)
                    // window.location.href = "/"
                    let user = getUserFromLocalStorage()
                    dispatch(loginUser(user))
                    navigate("/quote")
                })
            } else {
                setMessage("Login failed, please retry")
            }
        }).catch(error => {
            console.error(error)
            setMessage("Login failed, please retry")
        })
            .finally(() => dispatch(stopLoading()))
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container component="main" sx={{height: '100vh'}}>
                <CssBaseline/>
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/login-image.jpeg)`,
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <img src="/newboda.png" width='500px' height='180px' alt="Login Image"/>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                            <LockOutlinedIcon/>
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 1}}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Username"
                                name="username"
                                autoComplete="username"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{mt: 3, mb: 2}}
                                disabled={isLoading}
                            >
                                {isLoading ? <CircularProgress size={24} sx={{color: '#ffffff'}}/> : 'Sign In'}
                            </Button>
                            {message && <Typography color="error">{message}</Typography>}
                            <Copyright sx={{mt: 5}}/>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}