import './App.css';
import SignInSide from "./component/SignInSide";
import {isValidLogin} from "./services/AuthService";
import Dashboard from "./dashboard/Dashboard";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {logoutUser} from "./reducers/userSlice";
import {BrowserRouter} from "react-router-dom";

function App(props) {


    const loginUser = useSelector((state) => state.user.loginUser)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!isValidLogin() && window.location.pathname !== "/login") {
            dispatch(logoutUser())
            window.location.href = "/login"
        }
    }, [])

    useEffect(() => {
        props.tracker.setUserID(loginUser?.sub)
        //Do not start if it is localhost
        if (window.location.hostname !== "localhost") {
            props.tracker.start()
        }

    }, [loginUser])


    return (<BrowserRouter>
        <div className="App">
            {props.tracker.isActive()}
            {loginUser ? <div><Dashboard/></div> : <div><SignInSide/></div>}

        </div>
    </BrowserRouter>);
}

export default App;
