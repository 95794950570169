import React, { Fragment } from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import {v4 as uuidv4} from 'uuid';

const styles = StyleSheet.create({
    invoiceNoContainer: {
        flexDirection: 'row',
        marginTop: 36,
        justifyContent: 'flex-end'
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    invoiceDate: {
        fontFamily: 'Noto Sans SC',
        fontSize: 12,

    },
    label: {
        fontFamily: 'Noto Sans SC',
        width: 60,
        fontWeight: "600",
        textAlign: 'left',
    }

});


const InvoiceDate = ({invoice}) => (
    <Fragment>
        <View key={uuidv4()}  style={styles.invoiceDateContainer}>
            <Text style={styles.label}>Date:</Text>
            <Text style={styles.invoiceDate}>{invoice.invoiceDate}</Text>
        </View >
        <View key={uuidv4()}  style={styles.invoiceDateContainer}>
            <Text style={styles.label}>Expiry:</Text>
            <Text style={styles.invoiceDate}>{invoice.expireDate}</Text>
        </View >
    </Fragment>
);

export default InvoiceDate