import * as React from 'react';
import {useState} from 'react';
import Container from '@mui/material/Container';
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {Button, Tooltip} from "@mui/material";
import {saveQuote} from "../services/CartService";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Badge from "@mui/material/Badge";
import GoogleMaps from "./GoogleMaps";
import Pricing from "./Pricing";
import Grid from "@mui/material/Grid";
import CartTable from "../component/CartTable";
import {useDispatch, useSelector} from "react-redux";
import {startLoading, stopLoading} from "../reducers/loadingSlice";
import {
    clearCart,
    hideDialog,
    showDialog,
    updateQuote,
    updateQuoteAddress,
    updateQuoteDescription, updateQuoteReference
} from "../reducers/cartSlice";
import {useNavigate} from "react-router-dom";
import TextField from "@mui/material/TextField";
import {checkRoles} from "../services/AuthService";
import AdhocPrice from "../component/AdhocPrice";
import ImageUpload from "../component/ImageUpload";
import ImageGallary from "../component/ImageGallary";

export default function ShoppingCart(props) {

    const isLoading = useSelector((state) => state.loading.backend)
    const selectedUser = useSelector((state) => state.user.selectedUser)
    const cartItems = useSelector((state) => state.cart[selectedUser.id]?.nodes)
    const priceOverride = useSelector((state) => state.cart[selectedUser.id]?.priceOverride)
    const extraUnit = useSelector((state) => state.cart[selectedUser.id]?.extraUnit)
    const activeQuote = useSelector((state) => state.cart[selectedUser.id]?.activeQuote)
    const address = useSelector((state) => state.cart[selectedUser.id]?.address)
    const description = useSelector((state) => state.cart[selectedUser.id]?.description)
    const reference = useSelector((state) => state.cart[selectedUser.id]?.reference)

    const loginUser = useSelector((state) => state.user.loginUser)
    const images = useSelector((state) => state.cart[selectedUser.id]?.images)


    const dispatch = useDispatch()
    const [showSearch, setShowSearch] = useState(false);
    const navigate = useNavigate()


    function handleSaveQuote() {
        dispatch(startLoading())
        saveQuote(activeQuote, cartItems,
            selectedUser, priceOverride, extraUnit, address, description, images, reference).then(async (response) => {
            if (response.status === 200) {
                let data = await response.json()
                //Put whatever in the cart to the quote
                dispatch(updateQuote({
                    agentId: data.quoteId,
                    nodes: cartItems,
                    images: images,
                    priceOverride: priceOverride,
                    extraUnit: extraUnit,
                    address: address,
                    description: description,
                    reference: reference
                }));

                dispatch(showDialog({agentId: selectedUser.id, activeQuote: data.quoteId}))
            } else {
                console.error(response)
            }
        }).catch((error) => {
            console.error(error)
        }).finally(() => {
            dispatch(stopLoading())
        })
    }

    function handleStartNewQuote() {
        dispatch(hideDialog({agentId: selectedUser.id}))
        dispatch(clearCart({agentId: selectedUser.id}))
        navigate('/quote')
    }

    function handleAddressChange(newValue) {
        dispatch(updateQuoteAddress({agentId: selectedUser.id, address: newValue?.description}))
    }

    function handleQuoteNotesChange(event) {
        dispatch(updateQuoteDescription({agentId: selectedUser.id, description: event.target.value}))
    }

    function handleQuoteReferenceChange(event) {
        dispatch(updateQuoteReference({agentId: selectedUser.id, reference: event.target.value}))
    }


    return (
        <Container component="main" maxWidth="100%">


            <Typography sx={{
                my: {xs: 1, md: 1},
                pt: {xs: 1, md: 1},
                pb: {xs: 1, md: 1},
                backgroundColor: 'rgba(255, 152, 0, 0.08)',
                boxShadow: '0 2px 2px rgba(0, 0, 0, 0.3)'
            }}

                        align="center"
                        color="primary"
                        gutterBottom
            >
                <Badge badgeContent={cartItems && cartItems.length} color="primary" sx={{mr: 1}}>
                    <ShoppingCartIcon fontSize={'large'}/>
                </Badge>{selectedUser && selectedUser.name && selectedUser.name + "'s Cart"}
            </Typography>
            <CartTable selectedUser={selectedUser}
                       override={props.override}
                       readOnly={props.readOnly}
            />
            {checkRoles(loginUser, ['ADMIN', 'STAFF']) && (
                <Paper variant="outlined" sx={{
                    my: {xs: 2, md: 2}, py: {xs: 2, md: 2},
                    boxShadow: '0 2px 2px rgba(0, 0, 0, 0.3)',
                    px: {xs: 1, md: 1}, height: '100%'
                }}>
                    <AdhocPrice/>
                </Paper>
            )}

            <Paper variant="outlined" sx={{
                my: {xs: 3, md: 3},
                boxShadow: '0 2px 2px rgba(0, 0, 0, 0.3)',
                p: {xs: 3, md: 3}, height: '100%'
            }}>
                <GoogleMaps address={address} setAddress={handleAddressChange}/>

                <TextField
                    label="Quote Notes"
                    multiline
                    rows={3}
                    variant="outlined"
                    fullWidth
                    value={description}
                    onChange={handleQuoteNotesChange}
                    InputLabelProps={{shrink: true}} // To display the label above the TextField
                    placeholder="You can type your special requirement here" // The hint text
                    sx={{mt: 3}}
                />
                <ImageUpload/>
                <ImageGallary selectedUser={selectedUser}/>
            </Paper>
            {checkRoles(loginUser, ['ADMIN', 'STAFF']) && (

            <Paper variant="outlined" sx={{
                my: {xs: 3, md: 3},
                boxShadow: '0 2px 2px rgba(0, 0, 0, 0.3)',
                p: {xs: 3, md: 3}, height: '100%'
            }}>
                <TextField
                    label="Quote Reference"
                    multiline
                    rows={3}
                    variant="outlined"
                    fullWidth
                    value={reference}
                    onChange={handleQuoteReferenceChange}
                    InputLabelProps={{shrink: true}} // To display the label above the TextField
                    placeholder="Please Copy and Paste Original Requirement Here to train AI to categorize" // The hint text
                    sx={{mt: 3}}
                />
            </Paper>)}


            {(window.location.pathname === '/quote' || window.location.pathname === '/') &&
                <Grid sx={{my: 1}}>
                    <Button sx={{mx: 1}}
                            variant="contained"
                            onClick={() => {
                                navigate('/checkout')
                            }}>Check out</Button>

                    <Button sx={{mx: 1}}
                            variant="outlined"
                            onClick={() => {
                                dispatch(clearCart({agentId: selectedUser.id}))
                            }}>Clear</Button>
                </Grid>
            }
            {window.location.pathname === '/checkout' &&

                <Grid sx={{my: 1}}>
                    <Tooltip title={"This button will save current shopping cart"}>
                        <Button sx={{mx: 1}}
                                onClick={() => handleSaveQuote()}
                                disabled={isLoading || !cartItems || cartItems.length === 0}
                                variant="contained"
                        >{(activeQuote ? 'Update' : 'Save') + ' Quote'}</Button>
                    </Tooltip>
                    <Tooltip title={"Shopping Cart will be cleared and Start a new Quote"}>
                        <Button sx={{mx: 1}}
                                onClick={() => handleStartNewQuote()}
                                disabled={isLoading}
                                variant="outlined">
                            Start New Quote
                        </Button>
                    </Tooltip>
                    <Tooltip title={"Toggle this button to add more items into cart"}>
                        <Button sx={{mx: 1}}
                                onClick={() => setShowSearch(!showSearch)}
                                disabled={isLoading}
                                variant="outlined">
                            {showSearch ? 'Hide Search' : 'Add More'}
                        </Button>
                    </Tooltip>
                </Grid>
            }

            {showSearch &&
                <Paper variant="outlined" sx={{
                    my: {xs: 2, md: 2}, py: {xs: 2, md: 2},
                    boxShadow: '0 2px 2px rgba(0, 0, 0, 0.3)',
                    px: {xs: 1, md: 1}, height: '100%'
                }}>
                <Pricing
                    heading={false}/>
                </Paper>
                }


        </Container>
    );
}
