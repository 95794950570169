import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import {v4 as uuidv4} from 'uuid';
const borderColor = '#ffffff'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottom: '0.4 solid rgba(0,0,0,0.1)',
        alignItems: 'center',
        height: 24,
        fontWeight: 600,
        color: 'white'
    },
    lastRow: {
        flexDirection: 'row',
        borderBottom: '1 solid #000000',
        alignItems: 'center',
        height: 24,
        fontWeight: 600,
        color: 'white'
    },
    description: {
        width: '60%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    qty: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    rate: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    amount: {
        width: '15%',
    },

});

const InvoiceTableBlankSpace = ({rowsCount}) => {
    const blankRows = Array(rowsCount).fill(0)
    const rows = blankRows.map( (x, i) =>
        <View key={uuidv4()} style={styles.row} >
            <Text style={styles.description}>-</Text>
            <Text style={styles.qty}>-</Text>
            <Text style={styles.rate}>-</Text>
            <Text style={styles.amount}>-</Text>
        </View>
    )
    return (<Fragment>
        {rows}
        <View key={uuidv4()} style={styles.lastRow}>
            <Text style={styles.description}>-</Text>
            <Text style={styles.qty}>-</Text>
            <Text style={styles.rate}>-</Text>
            <Text style={styles.amount}>-</Text>
        </View>
    </Fragment> )
};

export default InvoiceTableBlankSpace