import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import {v4 as uuidv4} from 'uuid';

const styles = StyleSheet.create({

    titleContainer:{
        flexDirection: 'column',
        marginTop: 24,
    },
    reportTitle:{
        color: '#000000',
        letterSpacing: 1,
        fontSize: 20,
        textAlign: 'center',
        textTransform: 'uppercase',
    }
});


const InvoiceTitle = ({title, quoteId}) => (
    <View key={uuidv4()} style={styles.titleContainer}>
        <Text style={styles.reportTitle}>{title + ":  " + quoteId}</Text>
    </View>
);

export default InvoiceTitle