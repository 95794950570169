import * as React from "react";
import {useEffect, useRef, useState} from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {useSelector} from "react-redux";
import {calculateQuotePrice, formatPrice} from "../services/CartService";
import {styled} from '@mui/material/styles';
import {Button, ButtonGroup} from "@mui/material";
import {invoiceData} from "../component/invoices/invoice-data";
import Invoice from "../component/invoices/Invoice";
import {PDFDownloadLink} from "@react-pdf/renderer";
import EmailIcon from '@mui/icons-material/Email';
import SmsIcon from '@mui/icons-material/Sms';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import IconButton from "@mui/material/IconButton";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import {checkRoles} from "../services/AuthService";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


function getCurrentDate() {
    return new Date().toISOString().slice(0, 10);
}

// Get the date 14 days later in "yyyy-MM-dd" format
function getExpireDate() {
    const today = new Date();
    today.setDate(today.getDate() + 14);
    return today.toISOString().slice(0, 10);
}

export default function QuoteSummary(props) {

    const {selectedUser} = props
    const {
        nodes,
        priceOverride,
        extraUnit,
        activeQuote,
        address,
        description
    } = useSelector((state) => state.cart[selectedUser.id] || {});

    const [quoteItems, setQuoteItems] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(0)

    const [renderType, setRenderType] = useState('email');
    const [quotePdfData, setQuotePdfData] = useState(null);
    const [quoteId, setQuoteId] = useState(null);
    const loginUser = useSelector((state) => state.user.loginUser)

    const [copied, setCopied] = useState(false);
    const textRef = useRef(null);

    const [showDescriptions, setShowDescriptions] = useState(quoteItems ? quoteItems.map(() => true) : []);

    const toggleDescription = (index) => {
        const newShowDescriptions = [...showDescriptions];
        newShowDescriptions[index] = !newShowDescriptions[index];
        setShowDescriptions(newShowDescriptions);
    };


    useEffect(() => {

        let {quoteItems, totalPrice, maxPrice} = calculateQuotePrice(nodes, priceOverride, extraUnit, activeQuote)
        setQuoteItems(quoteItems)
        setTotalPrice(totalPrice)
        setMaxPrice(maxPrice)

        let computedQuoteId;

        if (activeQuote) {
            setQuoteId(activeQuote)
            computedQuoteId = activeQuote
        } else if (selectedUser && selectedUser.id && typeof selectedUser.id === 'string' && selectedUser.id.startsWith('QTE')) {
            setQuoteId(selectedUser.id)
            computedQuoteId = selectedUser.id
        }
        setQuotePdfData({
            ...invoiceData,
            items: quoteItems,
            quoteId: computedQuoteId,
            invoiceDate: getCurrentDate(),
            expireDate: getExpireDate(),
            jobAddress: address ? address : 'To be confirmed',
            totalPrice: totalPrice,
            maxPrice: maxPrice
        })

    }, [nodes, priceOverride, extraUnit, selectedUser, activeQuote]);

    const QuoteContainer = styled('div')({
        textAlign: 'left',
        padding: '20px',
        backgroundColor: '#ffffff',
        borderRadius: '8px',
        boxShadow: '0 2px 2px rgba(0, 0, 0, 0.3)',
    });

    const handleCopy = () => {
        if (textRef.current) {
            const range = document.createRange();
            range.selectNode(textRef.current);
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);
            document.execCommand('copy');
            window.getSelection().removeAllRanges();
            setCopied(true);
            // Reset the "Content copied" message after 2 seconds
            setTimeout(() => setCopied(false), 2000);
        }
    };


    const QuoteSummaryEmail = () => {
        const greeting = selectedUser?.name ? `Dear ${selectedUser.name},` : 'Hello,';

        return (
            <QuoteContainer style={{ position: 'relative' }}>
                <div  style={{ display: 'flex',position: 'absolute', top: 0, right: 0,
                    paddingRight: 15, paddingTop: 15, zIndex: 1, flexDirection: 'row-reverse' }}>
                    <IconButton
                        aria-label="copy"
                        onClick={handleCopy}
                        style={{ zIndex: 1 }}
                        title="Copy Content"
                    >
                        <FileCopyIcon />
                    </IconButton>
                    {copied && <div style={{ color: 'green', marginLeft: '5px' }}>Content copied</div>}
                </div>

                <div ref={textRef}>
                <div style={{fontFamily: 'Arial, sans-serif', fontSize: '16px'}}>
                    <p>{greeting}</p>

                    <p>
                        Your Property maintainance job quote is ready. <strong> Quote
                        ID: {quoteId ? quoteId : 'N/A'}</strong>
                    </p>

                    {address && (
                        <p>
                            <strong>Job Location:</strong> {address}
                        </p>
                    )}
                    {description && (
                        <p>
                            <strong>Notes:</strong> {description}
                        </p>
                    )}
                    <p>
                        <strong>Items:</strong>
                    </p>
                    {quoteItems && quoteItems.length > 0 && (
                        <ul>
                            {quoteItems.map((item, index) => {
                                return (
                                    <li key={index}>
                                        <span style={{ fontWeight: 600 }}>{item.name + ': '}</span>
                                        {item.quantity > 1 && (
                                            <> (Qty: {item.quantity}) </>
                                        )}
                                        {formatPrice(item.totalPrice)}
                                        {item.unitMaxPrice > 0 && item.unitMaxPrice > item.totalPrice && ' - ' + formatPrice(item.unitMaxPrice)}
                                        {item.description && (
                                            <>
                                                <IconButton size="small" onClick={() => toggleDescription(index)} title="Show Description">
                                                    {!showDescriptions[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </IconButton>
                                                <div style={{ marginLeft: '10px', fontStyle: 'italic', display: !showDescriptions[index] ? 'block' : 'none' }}>
                                                    {item.description}
                                                </div>
                                            </>
                                        )}
                                    </li>

                                );
                            })}
                        </ul>
                    )}
                    <p>
                        <strong>Total Quote Price:</strong> {formatPrice(totalPrice)} {maxPrice > 0 && maxPrice > totalPrice && ' - ' + formatPrice(maxPrice)} +GST
                    </p>
                    <p>Thank you for your business!</p>
                    <p>Best regards,</p>
                    <p>NewBoda Services</p>
                </div>
                </div>
            </QuoteContainer>
        );
    };


    const QuoteSummaryWechat = () => {
        return (
            <QuoteContainer style={{ position: 'relative' }}>
                <div  style={{ display: 'flex',position: 'absolute', top: 0, right: 0,
                    paddingRight: 15, paddingTop: 15, zIndex: 1, flexDirection: 'row-reverse' }}>
                    <IconButton
                        aria-label="copy"
                        onClick={handleCopy}
                        style={{ zIndex: 1 }}
                        title="Copy Content"
                    >
                        <FileCopyIcon />
                    </IconButton>
                    {copied && <div style={{ color: 'green', marginLeft: '5px' }}>Content copied</div>}
                </div>

                <div ref={textRef}>
                <Typography variant="h6" gutterBottom>
                    Quote ID: {quoteId ? quoteId : 'N/A'}
                </Typography>
                {address &&
                    <Typography variant="body1" gutterBottom>
                        Address: {address}
                    </Typography>
                }
                {description &&
                    <Typography variant="body1" gutterBottom>
                        Notes: {description}
                    </Typography>}
                <Typography variant="body1" gutterBottom>
                    Items:
                </Typography>
                <Typography variant="body2" component="div" gutterBottom>
                    <ul>
                        {quoteItems && quoteItems.map((item, index) => {
                            return (
                                <li key={index}>
                                    <span style={{ fontWeight: 600 }}>{item.name + ": "}</span>
                                    {item.quantity > 1 && (
                                        <> (Qty: {item.quantity}) </>
                                    )}
                                    {formatPrice(item.totalPrice)}
                                    {item.unitMaxPrice > 0 && item.unitMaxPrice > item.totalPrice && ' - ' + formatPrice(item.unitMaxPrice)}
                                    {item.description && (
                                        <>
                                            <IconButton size="small" onClick={() => toggleDescription(index)} title="Show Description">
                                                {!showDescriptions[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                            </IconButton>
                                            <div style={{ marginLeft: '10px', fontStyle: 'italic', display: !showDescriptions[index] ? 'block' : 'none' }}>
                                                {item.description}
                                            </div>
                                        </>
                                    )}
                                </li>
                            )
                        })}

                    </ul>
                </Typography>
                <Typography variant="body1">
                    Total Quote Price: {formatPrice(totalPrice)} {maxPrice > 0 && maxPrice > totalPrice && ' - ' + formatPrice(maxPrice)} +GST
                </Typography>
                </div>
            </QuoteContainer>
        );
    };

    const componentMap = {
        email: QuoteSummaryEmail,
        wechat: QuoteSummaryWechat,
        // sms: QuoteSummarySMS,
    };


    return (
        <Container component="main" maxWidth="100%">
            <Typography sx={{
                my: {xs: 1, md: 1},
                pt: {xs: 1, md: 1},
                pb: {xs: 1, md: 1},
                backgroundColor: 'rgba(255, 152, 0, 0.08)',
                minHeight: '51px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 'bold',
                boxShadow: '0 2px 2px rgba(0, 0, 0, 0.3)',

            }}
                        align="center"
                        color="primary"
                        gutterBottom
            >
                Quote Summary {quoteId}
            </Typography>
            {componentMap[renderType]()}
            <ButtonGroup variant="outlined" aria-label="outlined primary button group" sx={{my: 1}}>
                <Button startIcon={<EmailIcon/>} onClick={() => setRenderType('email')}>Email</Button>
                <Button startIcon={<SmsIcon/>} onClick={() => setRenderType('wechat')}>Wechat</Button>

                {quoteId && quotePdfData && checkRoles(loginUser, ['ADMIN', 'STAFF']) &&
                    <PDFDownloadLink document={<Invoice invoice={quotePdfData}/>} fileName={quoteId + '.pdf'}>
                        {({blob, url, loading, error}) =>
                            loading ? '' : <Button startIcon={<PictureAsPdfIcon/>} >{'Download PDF'} </Button>
                        }
                    </PDFDownloadLink>}
            </ButtonGroup>
        </Container>

    );

}
