import * as React from 'react';
import {useEffect} from 'react';
import Container from '@mui/material/Container';
import Paper from "@mui/material/Paper";
import ShoppingCart from "./ShoppingCart";
import Pricing from "./Pricing";
import Grid from "@mui/material/Grid";
import {setActiveQuote} from "../reducers/cartSlice";
import {useDispatch, useSelector} from "react-redux";
import {UserSelector} from "../component/UserSelector";


export default function Quote(props) {

    const selectedUser = useSelector((state) => state.user.selectedUser)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setActiveQuote({agentId: selectedUser.id, activeQuote: null}))
    }, [])

    return (
        <div>
            <Container maxWidth="100%" sx={{mt: 1, mb: 4, ml: 0}}>
                <Grid container spacing={0.5}>
                    <Grid item xs={12} md={12} lg={9}>
                        <Paper variant="outlined" sx={{
                            my: {xs: 0, md: 0},
                            p: {xs: 0, md: 0},
                            height: '100%'
                        }}>
                            <Pricing heading={true}/>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={12} lg={3} style={{position: 'sticky', top: '0px', height: '100%'}}>
                        <UserSelector/>
                        <ShoppingCart/>
                    </Grid>
                </Grid>

            </Container>
        </div>
    );
}
