import * as React from "react";
import {useEffect, useMemo, useState} from "react";
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {calculateQuotePrice, formatPrice, getQuoteByAgentId, toggleActiveQuote} from "../services/CartService";
import Container from "@mui/material/Container";
import CartTable from "../component/CartTable";
import Grid from "@mui/material/Grid";
import QuoteSummary from "./QuoteSummary";
import {useDispatch, useSelector} from "react-redux";
import {startLoading, stopLoading} from "../reducers/loadingSlice";
import {CircularProgress, InputAdornment, Switch, Tooltip} from "@mui/material";
import {setActiveQuote, updateQuote} from "../reducers/cartSlice";
import EditIcon from "@mui/icons-material/Edit";
import {useNavigate} from "react-router-dom";
import TextField from "@mui/material/TextField";
import {DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import SearchIcon from "@mui/icons-material/Search";
import {selectUser} from "../reducers/userSlice";
import {checkRoles} from "../services/AuthService";
import ImageGallary from "../component/ImageGallary";


function convertQuoteToItem(data) {
    const convertedData = [];

    data.prices && data.prices.forEach((price) => {
        const nodeId = price.nodeId;
        const nodeName = data.nodeIdToName[nodeId];
        const description = data.nodeIdToDescription[nodeId];
        const basePrice = price.basePrice;
        const bundlePrice = price.bundlePrice;
        const extraUnitName = price.extraUnitName;
        const upperPrice = price.upperPrice;
        const extraUnitPrice = price.extraUnitPrice;

        if (nodeName) {
            const convertedItem = {
                name: nodeName,
                description: description,
                id: nodeId,
                leaf: true,
                modifiedDate: data.modifiedDate,
                priceId: price.id,
                basePrice: basePrice,
                bundlePrice: bundlePrice,
                upperPrice: upperPrice,
                extraUnitName: extraUnitName,
                extraUnitPrice: extraUnitPrice,
                active: data.active
            };
            convertedData.push(convertedItem);
        }
    });
    return convertedData;
}

function Row(props) {
    const {row, toggleActiveStatus} = props;
    const [open, setOpen] = React.useState(false);
    const [totalPrice, setTotalPrice] = useState(0.0);
    const [maxPrice, setMaxPrice] = useState(0.0)

    const cartItems = useSelector((state) => state.cart[row.quoteId]?.nodes)
    const priceOverride = useSelector((state) => state.cart[row.quoteId]?.priceOverride)
    const extraUnit = useSelector((state) => state.cart[row.quoteId]?.extraUnit)
    const loginUser = useSelector((state) => state.user.loginUser)


    const dispatch = useDispatch()
    const navigate = useNavigate()


    useEffect(() => {
        let {maxPrice, totalPrice} = calculateQuotePrice(cartItems, priceOverride, extraUnit);
        setTotalPrice(totalPrice)
        setMaxPrice(maxPrice)
    }, [cartItems, priceOverride, extraUnit]);


    function handleEditQuote() {
        //Put whatever in the cart to the quote
        dispatch(selectUser({
            id: parseInt(row.agentId),
            userName: props.usersMap[row.agentId]?.userName,
            name: props.usersMap[row.agentId]?.name
        }))

        // dispatch(refreshCart({agentId: row.agentId, nodes: cartItems}))
        // dispatch(updatePriceOverride({agentId: row.agentId, priceOverride: priceOverride}))
        // dispatch(updateExtraUnit({agentId: row.agentId, extraUnit: extraUnit}))
        // dispatch(updateQuoteAddress({agentId: row.agentId, address: row.address}))
        // dispatch(updateQuoteDescription({agentId: row.agentId, description: row.description}))

        dispatch(setActiveQuote({agentId: row.agentId, activeQuote: row.quoteId}))

        dispatch(updateQuote({
            agentId: row.agentId,
            nodes: cartItems,
            images: row.images,
            priceOverride: priceOverride ?? {},
            extraUnit: extraUnit ?? {},
            address: row.address,
            description: row.description,
            reference: row.reference,
        }));


        navigate('/checkout')

    }

    const rowStyle = {
        textDecoration: row.active ? 'none' : 'line-through'
    };


    return (
        <React.Fragment>

                <TableRow sx={{
                    borderBottom: 'unset',
                    cursor: 'pointer',
                    border: '1px solid rgba(200, 200, 200, 0.3)',
                    ":hover": {
                        backgroundColor: '#f5f5f5',
                    }
                }}
                          onClick={() => setOpen(!open)}>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                        </IconButton>
                    </TableCell>
                    <Tooltip
                        title={row.active ? 'Click to view details' : row.quoteId + ' is marked as INACTIVE, it will not be processed'}>
                    <TableCell sx={{
                        textDecoration: row.active ? 'none' : 'line-through',
                        color: row.active ? "black" : "red"
                    }}>{row.quoteId}</TableCell>
                    </Tooltip>
                    <TableCell>{props.usersMap[row.agentId]?.userName ?? "[Deleted User]: " + row.agentId}</TableCell>
                    <TableCell>{row.address}</TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>{formatPrice(totalPrice)} {maxPrice > 0 && maxPrice > totalPrice && ' - ' + formatPrice(maxPrice)} </TableCell>
                    <TableCell>{new Date(row.modifiedDate).toLocaleString()}
                        {checkRoles(loginUser, ['ADMIN', 'STAFF']) && (<> by {row.modifiedBy}</>)}
                        {checkRoles(loginUser, ['ADMIN', 'STAFF']) && (
                            <IconButton title="Edit Quote"
                                        onClick={handleEditQuote}>
                                <EditIcon/>
                            </IconButton>
                        )}
                    </TableCell>
                    <TableCell><Switch
                        checked={row.active}
                        onClick={(e) => toggleActiveStatus(e, row.quoteId)}
                    />
                    </TableCell>
                </TableRow>

            <TableRow sx={{
                border: '1px solid rgba(200, 200, 200, 0.3)',
            }}>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>

                        <Container maxWidth="100%" sx={{
                            mt: 1, mb: 4, ml: 0,
                            backgroundColor: '#f5f5f5',
                        }}>
                            <Grid container spacing={0.5}>
                                <Grid item xs={12} md={12} lg={8}>
                                    <Typography sx={{
                                        my: {xs: 1, md: 1},
                                        pt: {xs: 1, md: 1},
                                        pb: {xs: 1, md: 1},
                                        backgroundColor: 'rgba(255, 152, 0, 0.08)',
                                        minHeight: '51px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        boxShadow: '0 2px 2px rgba(0, 0, 0, 0.3)',
                                        fontWeight: 'bold',
                                        justifyContent: 'center'
                                    }}
                                                align="center"
                                                color="primary"
                                                gutterBottom
                                    >
                                        Quote Details
                                    </Typography>
                                    <CartTable selectedUser={{id: row.quoteId}}
                                               override={false}
                                               readOnly={true}
                                    />
                                    {row.images && row.images.length > 0 && (
                                        <Paper variant="outlined" sx={{my: {xs: 1, md: 1}, p: {xs: 3, md: 3}}}>
                                            <ImageGallary selectedUser={{id: row.quoteId}} readOnly={true}/>
                                        </Paper>)}


                                </Grid>
                                <Grid item xs={12} md={12} lg={4}
                                      style={{position: 'sticky', top: '0px', height: '100%'}}>
                                    <QuoteSummary
                                        selectedUser={{id: row.quoteId}}
                                    />
                                </Grid>
                            </Grid>


                        </Container>


                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export function History(props) {

    const [quotes, setQuotes] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [sortConfig, setSortConfig] = useState({key: null, direction: 'ascending'});
    const allUsers = useSelector((state) => state.user.allUsers)


    const [startDate, setStartDate] = useState(() => {
        const today = new Date();
        const last14Days = new Date(today);
        last14Days.setDate(today.getDate() - 14);
        return last14Days;
    });

    const [endDate, setEndDate] = useState(() => {
        const today = new Date();
        const future7Days = new Date(today);
        future7Days.setDate(today.getDate() + 7);
        return future7Days;
    });

    const selectedUser = useSelector((state) => state.user.selectedUser)
    const isLoading = useSelector((state) => state.loading.backend)
    const dispatch = useDispatch()

    const usersMap = useMemo(() => {
        return allUsers.reduce((acc, user) => {
            acc[user.id] = user;
            return acc;
        }, {});
    }, [allUsers]);


    useEffect(() => {
        dispatch(startLoading())
        getQuoteByAgentId(selectedUser.id, startDate, endDate).then(async (response) => {
            if (response.status === 200) {
                let data = await response.json()
                setQuotes(data)

                data.map((item) => {
                    let row = convertQuoteToItem(item)
                    dispatch(updateQuote({
                        agentId: item.quoteId,
                        nodes: row,
                        images: item.images,
                        priceOverride: item.priceOverride ?? {},
                        extraUnit: item.extraUnit ?? {},
                        address: item.address,
                        description: item.description,
                        reference: item.reference,
                    }));
                })
            } else {
                console.error(response.text)
            }
        }).catch((error) => {
            console.error(error)
        }).finally(() => {
            dispatch(stopLoading())
        })
    }, [selectedUser, startDate, endDate]);


    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({key, direction});
    };

    const filterQuotes = (quotes) => {
        return quotes.filter((quote) => {
            if (!searchText) return true;
            return (
                quote.quoteId?.toString().toLowerCase().includes(searchText.toLowerCase()) ||
                usersMap[quote.agentId]?.userName?.toString().includes(searchText.toLowerCase()) ||
                quote.address?.toLowerCase().includes(searchText.toLowerCase()) ||
                quote.description?.toLowerCase().includes(searchText.toLowerCase())
            );
        });
    };

    // Sort the quotes based on the sortConfig
    const sortedQuotes = React.useMemo(() => {
        if (sortConfig.key !== null) {
            const sorted = [...quotes].sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
            return sorted;
        }
        return quotes;
    }, [quotes, sortConfig]);

    const handleSearch = (event) => {
        setSearchText(event.target.value);
    };

    const toggleActiveStatus = (e, quoteId) => {
        e.stopPropagation()
        dispatch(startLoading())
        let status = e.target.checked ? true : false
        console.log(status)
        toggleActiveQuote(quoteId, status).then(async (response) => {
            if (response.status === 200) {
                setQuotes((prevQuotes) => {
                    return prevQuotes.map((quote) =>
                        quote.quoteId === quoteId ? {...quote, active: status} : quote
                    );
                });
            } else {
                console.error(response.text)
            }
        }).finally(() => {
            dispatch(stopLoading())
        });

    };


    return (
        <Container maxWidth="xl">
            {/* User's Quote History */}
            <Typography
                component="h4"
                variant="h5"
                sx={{
                    my: {xs: 3},
                    pt: {xs: 3},
                    pb: {xs: 3},
                    backgroundColor: 'rgba(255, 152, 0, 0.08)',
                    boxShadow: '0 2px 2px rgba(0, 0, 0, 0.3)',
                    fontWeight: 'bold',
                }}
                align="center"
                color="primary"
                gutterBottom
            >

                QUOTE HISTORY

            </Typography>
            {!quotes ? <CircularProgress size={50} /> : null}
            {/* Search input */}
            <Grid container spacing={2}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Grid item xs={3}>
                        <DesktopDatePicker
                            label="Start Date"
                            value={startDate}
                            onChange={(date) => setStartDate(date)}
                            renderInput={(params) => <TextField {...params} />}
                            sx={{mt: 2, mb: 2}}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <DesktopDatePicker
                            label="End Date"
                            value={endDate}
                            onChange={(date) => setEndDate(date)}
                            renderInput={(params) => <TextField {...params} />}
                            sx={{mt: 2, mb: 2}}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Search"
                            variant="outlined"
                            value={searchText}
                            onChange={handleSearch}
                            sx={{mt: 2, mb: 2}}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon/>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>


                </LocalizationProvider>
            </Grid>


            {/* Quotes Table */}
            <TableContainer component={Paper} sx={{mt: 3, borderRadius: '8px'}}>
                <Table aria-label="collapsible table" size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell/>
                            <TableCell
                                onClick={() => handleSort("quoteId")}
                                sx={{cursor: "pointer", fontWeight: "bold"}}
                            >
                                Quote ID
                                {sortConfig.key === "quoteId" && (
                                    sortConfig.direction === "ascending" ? " ▲" : " ▼"
                                )}
                            </TableCell>
                            <TableCell
                                onClick={() => handleSort("agentId")}
                                sx={{cursor: "pointer", fontWeight: "bold"}}
                            >
                                Agent Name
                                {sortConfig.key === "agentId" && (
                                    sortConfig.direction === "ascending" ? " ▲" : " ▼"
                                )}
                            </TableCell>
                            <TableCell sx={{fontWeight: "bold"}}>Address</TableCell>
                            <TableCell sx={{fontWeight: "bold"}}>Quote Notes</TableCell>
                            <TableCell sx={{fontWeight: "bold"}}>
                                Total Price
                            </TableCell>
                            <TableCell sx={{fontWeight: "bold"}}>Modified Date</TableCell>
                            <TableCell sx={{fontWeight: "bold"}}>Active</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>


                        {filterQuotes(sortedQuotes).map((row) => (
                            <Row key={row.quoteId} row={row} usersMap={usersMap}
                                 toggleActiveStatus={toggleActiveStatus}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
}
