import {getheaders} from "./AuthService";

const CART_KEY = 'cart';


export function attachImageToQuote(apiImage, quoteId) {

}


export function saveQuote(activeQuote, cartItems, selectedUser, priceOverride, extraUnit, address, description, images, reference) {
    let method = 'POST'
    if (activeQuote) {
        method = 'PUT'
    }
    let reqBody = {
        quoteId: activeQuote,
        agentId: selectedUser.id,
        address: address,
        description: description,
        extraUnit: extraUnit,
        priceOverride: priceOverride,
        images: images,
        reference: reference,
        prices: cartItems.map((item) => {
            return {id: item.priceId}
        })
    }
    let requestOptions = {
        method: method, headers: getheaders(), redirect: 'follow', body: JSON.stringify(reqBody)
    }
    return fetch(process.env.REACT_APP_BACKEND_URL + "/api/quote", requestOptions)
}


export function getQuoteByAgentId(agentId, startDate, endDate) {
    let requestOptions = {
        method: 'GET', headers: getheaders(), redirect: 'follow'
    }
    return fetch(process.env.REACT_APP_BACKEND_URL + "/api/quote/agent/" + agentId + "?startDate=" + startDate.getTime() + "&endDate=" + endDate.getTime(), requestOptions)
}

export function toggleActiveQuote(quoteId, status) {
    let requestOptions = {
        method: 'PUT', headers: getheaders(), redirect: 'follow'
    }
    return fetch(process.env.REACT_APP_BACKEND_URL + "/api/quote/status/" + quoteId + '?status=' + status, requestOptions)
}

export function calculateQuotePrice(cartItems, priceOverride, extraUnit) {
    let totalPrice = 0;
    let maxPrice = 0;
    const isBundledPriceUsedNewMap = {};
    let quoteItems = []

    if (cartItems) {
        const sortedItems = [...cartItems].sort((a, b) => {
            return getOverrideOrBasePrice(b, priceOverride) - getOverrideOrBasePrice(a, priceOverride)
        })
        sortedItems.forEach((item, index) => {
            let quoteItem = {
                itemId: item.id,
                name: item.name,
                description: item.description,
                quantity: 1,
                unitPrice: 0,
                totalPrice: 0,
            }

            //If there is extra item, then add extra price
            if (extraUnit[item.id] && extraUnit[item.id] > 0) {
                totalPrice += extraUnit[item.id] * item.extraUnitPrice;
                maxPrice += extraUnit[item.id] * item.extraUnitPrice;
                quoteItems.unshift({ ...quoteItem,
                    name: item.name + ' (Extra)' + item.extraUnitName,
                    quantity: extraUnit[item.id],
                    unitPrice: item.extraUnitPrice,
                    totalPrice: extraUnit[item.id] * item.extraUnitPrice,
                })
            }
            if (!getOverrideOrBasePrice(item, priceOverride)) {
                return;
            }

            //If there is override, only use override price
            if (priceOverride[item.id] && priceOverride[item.id] > 0) {
                totalPrice += priceOverride[item.id];
                maxPrice += priceOverride[item.id];
                quoteItems.unshift({ ...quoteItem,
                    unitPrice: priceOverride[item.id],
                    totalPrice: priceOverride[item.id],
                })
                return;
            }
            if (index === 0 || !(item.bundlePrice > 0)) {
                totalPrice += getOverrideOrBasePrice(item, priceOverride);
                if (item.upperPrice > getOverrideOrBasePrice(item, priceOverride)) {
                    maxPrice += item.upperPrice;
                    quoteItems.unshift({ ...quoteItem,
                        unitPrice: getOverrideOrBasePrice(item, priceOverride),
                        totalPrice: getOverrideOrBasePrice(item, priceOverride),
                        unitMaxPrice: item.upperPrice,
                        totalMaxPrice: item.upperPrice,
                    })
                } else {
                    maxPrice += getOverrideOrBasePrice(item, priceOverride);
                    quoteItems.unshift({ ...quoteItem,
                        unitPrice: getOverrideOrBasePrice(item, priceOverride),
                        totalPrice: getOverrideOrBasePrice(item, priceOverride),
                    })
                }
                isBundledPriceUsedNewMap[item.id] = false
            } else {
                totalPrice += item.bundlePrice;
                maxPrice += item.bundlePrice;
                quoteItems.unshift({ ...quoteItem,
                    unitPrice: item.bundlePrice,
                    totalPrice: item.bundlePrice,
                })
                isBundledPriceUsedNewMap[item.id] = true
            }
        });
    }
    return {totalPrice, maxPrice, isBundledPriceUsedNewMap, quoteItems};
}

function getOverrideOrBasePrice(item, priceOverride) {
    if (priceOverride[item.id] && priceOverride[item.id] > 0) {
        return priceOverride[item.id];
    } else {
        return item.basePrice;
    }
}

export function formatPrice(price) {

    return price && price.toLocaleString('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
}
