import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import {v4 as uuidv4} from 'uuid';
const styles = StyleSheet.create({
    headerContainer: {
        flexDirection: 'column',
        marginTop: 12,

    },
    billTo: {
        paddingBottom: 3,
        fontFamily: 'Helvetica-Oblique',
    },
    billLabel: {
        textAlign: 'left', // Add this line to align the text to the right
        width: '200px', // Specify a width
    }
});

const BillTo = ({ invoice }) => (
    <View key={uuidv4()} style={styles.headerContainer}>

        {/*<Text style={styles.billLabel}>{invoice.company}</Text>*/}
        <Text style={styles.billLabel}>{invoice.address}</Text>
        <Text style={styles.billLabel}>{invoice.abn}</Text>
        <Text style={styles.billLabel}>{invoice.phone}</Text>
        <Text style={styles.billLabel}>{invoice.email}</Text>
    </View>
);

export default BillTo;
