import {useDispatch, useSelector} from "react-redux";
import {checkRoles} from "../services/AuthService";
import {Autocomplete} from "@mui/material";
import {selectUser} from "../reducers/userSlice";
import TextField from "@mui/material/TextField";
import * as React from "react";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";


export function UserSelector() {
    const isLoading = useSelector((state) => state.loading.backend)
    const allUsers = useSelector((state) => state.user.allUsers)
    const selectedUser = useSelector((state) => state.user.selectedUser)
    const loginUser = useSelector((state) => state.user.loginUser)

    const dispatch = useDispatch()

    return (
        <Container>
            {checkRoles(loginUser, ['ADMIN', 'STAFF']) && (
                <Paper variant="outlined" sx={{my: {xs: 1, md: 1}, p: {xs: 2.5, md: 2.5}}}>

                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    disableClearable
                    fullWidth={true}
                    disabled={isLoading}
                    options={allUsers}
                    value={selectedUser}
                    onChange={(event, newValue) => {
                        dispatch(selectUser(newValue))
                    }}
                    getOptionLabel={(user) => user.name} // Specify the property to display in the autocomplete
                    isOptionEqualToValue={(option, value) => option && option.id === value.id}
                    renderInput={(params) =>
                        <TextField {...params} InputLabelProps={{
                            shrink: true,
                        }} label="Switch User"/>}
                    renderOption={(props, user) => (
                        <li {...props}>
                            {/* Render additional information about the user if needed */}
                            {user.name}
                        </li>
                    )}
                />
                </Paper>
            )}
        </Container>)
}