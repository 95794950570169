import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import * as React from "react";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import ListItem from "@mui/material/ListItem";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import Typography from "@mui/material/Typography";
import {Input, Tooltip} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {checkRoles} from "../services/AuthService";
import {updateExtraUnit} from "../reducers/cartSlice";
import {formatPrice} from "../services/CartService";

export default function PriceList(props) {
    const isLoading = useSelector((state) => state.loading.backend)
    const selectedUser = useSelector((state) => state.user.selectedUser)
    const loginUser = useSelector((state) => state.user.loginUser)
    const cartItems = useSelector((state) => state.cart[selectedUser.id]?.nodes) || []

    const extraUnit = useSelector((state) => state.cart[selectedUser.id]?.extraUnit)
    const dispatch = useDispatch()


    function inCart() {
        return cartItems && cartItems.filter(item => item.id === props.pricecard.id).length > 0;
    }

    return (
        <ListItem
            sx={{
                border: '1px solid rgba(200, 200, 200, 0.3)',
                boxShadow: '0 2px 2px rgba(0, 0, 0, 0.3)',
                borderRadius: '5px',
            }}
            secondaryAction={
                <div style={{display: 'flex', alignItems: 'center'}}>


                    {props.pricecard.basePrice && props.pricecard.leaf && !inCart() && (
                        //Has Base Price and is leaf and not in cart, then show the price
                        <>
                            <Typography>
                                ${props.pricecard.basePrice.toFixed(2)}
                            </Typography>
                            {props.pricecard.upperPrice && props.pricecard.upperPrice > 0 &&
                                <Typography sx={{mx: '5px'}}>
                                    to ${props.pricecard.upperPrice.toFixed(2)}
                                </Typography>}
                        </>
                    )}
                    {!inCart() && (
                        //Is leaf and not in cart
                        <Typography variant="subtitle2" sx={{mx: '10px'}}>
                            {props.pricecard.bundlePrice && (
                                <span style={{fontSize: '0.8em'}}>
                        {props.pricecard.bundlePrice.toFixed(2)} (if bundled)
                    </span>
                            )}
                            {props.pricecard.extraUnitPrice > 0 && (
                                <span style={{fontSize: '0.8em', marginLeft: '5px'}}>
                        plus ${props.pricecard.extraUnitPrice}
                    </span>
                            )}
                            {props.pricecard.extraUnitName && (
                                <span style={{fontSize: '0.8em'}}>
                        per {props.pricecard.extraUnitName}
                    </span>
                            )}
                        </Typography>
                    )}
                    {/*{props.pricecard.leaf && inCart() && props.pricecard.extraUnitPrice > 0 && (*/}
                    {/*    <Typography variant="subtitle2" sx={{mx: '10px'}}>*/}
                    {/*        <Input*/}
                    {/*            variant="standard"*/}
                    {/*            label=""*/}
                    {/*            // readOnly={readOnly}*/}
                    {/*            value={extraUnit[props.pricecard.id]}*/}
                    {/*            onChange={(e) => handleInputChange(e, props.pricecard, extraUnit, updateExtraUnit, 'extraUnit')}*/}
                    {/*            type="tel"*/}
                    {/*            sx={{*/}
                    {/*                width: 25, '& input': {*/}
                    {/*                    textAlign: 'center',*/}
                    {/*                }*/}
                    {/*            }}*/}

                    {/*        /> x &nbsp;*/}
                    {/*        {'extra ' + props.pricecard.extraUnitName}*/}
                    {/*    </Typography>*/}
                    {/*)}*/}

                    <div>
                        {checkRoles(loginUser, ['ADMIN']) && (
                            <IconButton
                                href={"/edit-node?id=" + props.pricecard.id}
                                disabled={isLoading}
                                aria-label="settings"
                                onClick={() => props.handleEditClick(props.pricecard)}>
                                <EditIcon/>
                            </IconButton>)}
                        <IconButton
                            aria-label="settings"
                            onClick={() => props.handleCardAction(props.pricecard)}
                            disabled={isLoading || inCart()}
                        >
                            {props.pricecard.leaf ? <ShoppingCartIcon color='primary'/> : <ReadMoreIcon/>}
                        </IconButton>
                    </div>
                </div>
            }>


            <Tooltip title={props.pricecard.leaf ? "Add to cart" : "Click for more information."}>
                <ListItemButton

                    onClick={() => props.handleCardAction(props.pricecard)}
                    disabled={isLoading || inCart()}
                    sx={{
                        width: '600px',
                    }}>
                    <ListItemText

                        primary={
                            <Typography sx={{ fontWeight: '600' }}>
                                {props.pricecard.name + (inCart() ? " (in cart)" : "")}
                            </Typography>
                        }
                        secondaryTypographyProps={{
                            sx: {
                                maxWidth: '600px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }
                        }}
                        secondary={
                            <>
                                {props.pricecard.description && props.pricecard.description.length > 0 ? props.pricecard.description : "No description available"}

                            </>
                        }
                    />

                </ListItemButton>
            </Tooltip>
        </ListItem>


    )
}
      